import localStorageHelper from '@core/localstorageHelper';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StateKind } from '@common/state';
import { IUser } from '../logic/domain/User';
import { loginAsync, getUserFromToken } from './loginAsync';
import { resetPassword } from './resetPassword';

export { loginAsync, getUserFromToken } from './loginAsync';
export { forgotPassword } from './forgotPassword';
export { resetPassword } from './resetPassword';

export interface AuthState {
  status: StateKind;
  isLoggedIn: boolean;
  user?: IUser;
  resetPasswordSuccess: boolean;
  //isTokenExpired?: () => boolean;
}

const initialState = ((): AuthState => {
  const token = localStorageHelper.getAuthToken();

  const initState: AuthState = {
    status: 'notLoaded',
    isLoggedIn: false,
    resetPasswordSuccess: false,
  };

  if (token) {
    const user = getUserFromToken(token);

    if (user) {
      // check expiration of the token
      initState.isLoggedIn = true;
      initState.user = user;
    }

  }

  return initState;
})();

export const authStore = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   // state.value += action.payload;
    // },
    logout: (state) => {
      state.isLoggedIn = false;
      state.user = undefined;
      localStorageHelper.removeAuthToken();
      localStorageHelper.removeRefreshToken();
    },
    callResetPassword: (state) => {
      state.resetPasswordSuccess = false;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state: AuthState) => {
        state.status = 'loading';
      })
      .addCase(loginAsync.fulfilled, (state: AuthState, action: PayloadAction<IUser>) => {
        state.status = 'loaded';
        state.isLoggedIn = true;
        state.user = { ...state.user, ...action.payload };
      })
      .addCase(loginAsync.rejected, (state: AuthState, error) => {
        state.status = 'failed';
        state.isLoggedIn = false;
      })
      .addCase(resetPassword.pending, (state: AuthState) => {
        state.resetPasswordSuccess = false;
      })
      .addCase(resetPassword.fulfilled, (state: AuthState) => {
        state.resetPasswordSuccess = true;
      });
  }
});

export const { callResetPassword, logout } = authStore.actions;

export default authStore.reducer;
