import { useAppDispatch, useAppSelector } from "@core/hooks";
import { getSiteSettings } from "app/store/appStore";
import { useEffect } from "react";

const LoadSiteSettings = () => {
    const dispatch = useAppDispatch();
    const generalSettings = useAppSelector((state) => state.app.generalSettings);

    useEffect(() => {
        if (generalSettings?.smallLogoUrl) {
            const link: HTMLLinkElement = document.querySelector("link[rel*='icon']") || document.createElement('link');
            link.type = 'image/x-icon';
            link.rel = 'shortcut icon';
            link.href = generalSettings.smallLogoUrl;
            document.head.appendChild(link);
        }

        document.title = `${generalSettings?.companyName} Portal`;
    }, [generalSettings]);

    useEffect(() => {
        dispatch(getSiteSettings());
      }, [])

    return (
        <></>
    )
}

export default LoadSiteSettings;