import useAppTheme from "@core/appTheme";
import DangerousIcon from '@mui/icons-material/Dangerous'
import OrderDetailReferences from './references/OrderDetailReferences';
import AddIcon from '@mui/icons-material/Add';
import { ChangeEvent, useState } from 'react';
import { dateFormat } from '@core/dateHelper';
import { useAppDispatch, useAppSelector } from '@core/hooks';
import { OrderReferencesActions } from 'orderDetail/@store/orderReferencesStore';
import { Tabs, Tab, Box, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, IconButton } from '@mui/material';
import { useFullWidth } from 'app/styles/styleExtension';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  tab: {
    "&.MuiTab-root": {
      minHeight: "55px"
    }
  }
}));

const OrderDetailTab = () => {
  const dispatch = useAppDispatch();

  const theme = useAppTheme();
  const widthClasses = useFullWidth();
  const styles = useStyles();

  const { order, statusMessages } = useAppSelector((state) => state.orderDetail);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event: ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const getEventLocationType = (eventGuid: string | undefined) => {
    const event = order?.events.find(x => x.guid === eventGuid);

    return (event?.type && event?.index) ? `${event?.type} ${event?.index}:` : "";
  }

  const handleShowCreateReference = () => {
    dispatch(OrderReferencesActions.showCreateReference());
  }

  return (
    <Box className={widthClasses.root}>
      <Tabs value={selectedTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        sx={{
          width: "max-content"
        }}
      >
        <Tab label="References" color={theme.palette.getContrastText(theme.palette.background.default)}
          className={styles.tab}
          icon={
            <IconButton onClick={() => handleShowCreateReference()}>
              <AddIcon fontSize='medium' color='primary' />
            </IconButton>
          }
          iconPosition='end'
        />
        <Tab label="Status Messages" color={theme.palette.getContrastText(theme.palette.background.default)} />
        <Tab label="Item Detail" color={theme.palette.getContrastText(theme.palette.background.default)} />
      </Tabs>
      <Box>
        {selectedTab === 0 &&
          <OrderDetailReferences />
        }
        {selectedTab === 1 &&
          <Table>
            <TableHead sx={{
              backgroundColor: theme.palette.action.hover
            }}>
              <TableRow>
                <TableCell>Detail </TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Location/Stop</TableCell>
                <TableCell>Integration Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ backgroundColor: theme.palette.background.paper }}>
              {statusMessages?.elements.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{item.statusCodeDisplayedValue}</TableCell>
                    <TableCell>{dateFormat(item.statusDate!)}</TableCell>
                    <TableCell>
                      {`${getEventLocationType(item.eventGuid) ?? ''}${getEventLocationType(item.eventGuid) && ' '}
                      ${item.location?.address1 ? item.location.address1 + ', ' : ''}
                      ${item.location?.city ? item.location.city + ', ' : ''}
                      ${item.location?.state ? item.location.state + ', ' : ''}
                      ${item.location?.zip ? item.location.zip + ', ' : ''}
                      ${item.location?.country ? item.location.country : ''}`}
                    </TableCell>
                    <TableCell>{item.infoFromIntegration}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        }
        {selectedTab === 2 &&
          <TableContainer sx={{ whiteSpace: 'nowrap' }}>
            <Table>
              <TableHead sx={{
                backgroundColor: theme.palette.action.hover
              }}>
                <TableRow>
                  <TableCell>Item ID</TableCell>
                  <TableCell>FreightClass</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Weight</TableCell>
                  <TableCell>Weight Type</TableCell>
                  <TableCell>Package Type</TableCell>
                  <TableCell>Dimensions Length</TableCell>
                  <TableCell>Dimensions Weight</TableCell>
                  <TableCell>Dimensions Height</TableCell>
                  <TableCell>Dimensions UOM</TableCell>
                  <TableCell>Volume</TableCell>
                  <TableCell>Volume UOM</TableCell>
                  <TableCell>Temperature Minimum</TableCell>
                  <TableCell>Temperature Maximum</TableCell>
                  <TableCell>Temperature UOM</TableCell>
                  <TableCell>Hazardous</TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ backgroundColor: theme.palette.background.paper }}>
                {order?.items.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{item.itemId}</TableCell>
                      <TableCell>{item.freightClass}</TableCell>
                      <TableCell>{item.description}</TableCell>
                      <TableCell>{item.weight}</TableCell>
                      <TableCell>{item.weightUOM}</TableCell>
                      <TableCell>{item.packageType}</TableCell>
                      <TableCell>{item.dimensionsL}</TableCell>
                      <TableCell>{item.dimensionsW}</TableCell>
                      <TableCell>{item.dimensionsH}</TableCell>
                      <TableCell>{item.dimensionsUOM}</TableCell>
                      <TableCell>{item.volume}</TableCell>
                      <TableCell>{item.volumeUOM}</TableCell>
                      <TableCell>{item.temperatureLow}</TableCell>
                      <TableCell>{item.temperatureHigh}</TableCell>
                      <TableCell>{item.temperatureUOM}</TableCell>
                      <TableCell>{item.hazardous ? <DangerousIcon color="error" /> : ""}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        }
      </Box>
    </Box>
  );
}

export default OrderDetailTab;