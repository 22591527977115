import { getServerAddress } from "@core/appVariables";
import { fetchClient } from "@core/fetchClient";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IAmosMapItemsDto, IAmosMapOrderDetailDto, IAmosOrderRequestDto } from "contracts/amos";

export const getDockSchedulerMapOrders = createAsyncThunk(
    'dock-scheduler/map/orders',
    async (request: IAmosOrderRequestDto) => {
        const response = await fetchClient().post<IAmosOrderRequestDto, IAmosMapItemsDto>(`${getServerAddress('/orders/api')}/map`, request);

        return response;
    }
);

export const getMapOrderDetail = createAsyncThunk(
    'dock-scheduler/map/order/details',
    async (orderGuid: string) => {
      const response = await fetchClient().get<IAmosMapOrderDetailDto>(`${getServerAddress('/orders/api')}/map/order/${orderGuid}`);
  
      return response;
    }
  );