import { StateKind } from "@common/state";
import { downloadFile } from "@common/nalFileManager";
import { fetchClient } from "@core/fetchClient";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IAmosDocumentsDto, IAmosLastStatusMessageListDto, IAmosOrderDetailsDto } from "contracts/amos";
import { getServerAddress } from "@core/appVariables";

export interface IOrderDetailState {
    status: StateKind;
    order: IAmosOrderDetailsDto | undefined;
    documents: IAmosDocumentsDto | undefined;
    statusMessages: IAmosLastStatusMessageListDto | undefined;
}

interface IDownloadOrder {
    orderGuid: string;
    documentGuid: string;
    availableDocumentTypeGuids?: string;
}

interface IOrderDocuments {
    orderGuid: string;
    availableDocumentTypeGuids: string[];
}

const initialState: IOrderDetailState = {
    status: 'notLoaded',
    order: undefined,
    documents: undefined,
    statusMessages: undefined,
};


export const getOrderDetails = createAsyncThunk(
    'details/{orderGuid}',
    async (orderGuid: string) => {
        const response = await fetchClient().get<IAmosOrderDetailsDto>(`${getServerAddress('/orders/api')}/orders/details/${orderGuid}`);

        return response;
    }
);

export const getOrderDocuments = createAsyncThunk(
    'details/{orderGuid}/documents',
    async (props: IOrderDocuments) => {
        const response = await fetchClient().post<string[], IAmosDocumentsDto>(`${getServerAddress('/orders/api')}/documents/${props.orderGuid}/list`, props.availableDocumentTypeGuids);

        return response;
    }
);

export const getOrderStatusMessages = createAsyncThunk(
    'details/{orderGuid}/messages',
    async (orderGuid: string) => {
        const response = await fetchClient().get<IAmosLastStatusMessageListDto>(`${getServerAddress('/orders/api')}/orders/details/${orderGuid}/messages`);

        return response;
    }
);

export const downloadDocument = createAsyncThunk(
    'details/{orderGuid}/documents/{documentGuid}',
    async (props: IDownloadOrder) => {
        const fileData = await fetchClient().stream(`${getServerAddress('/orders/api')}/documents/${props.orderGuid}/${props.documentGuid}`,"GET",);

        downloadFile(fileData.name, fileData.contentType, new Uint8Array(await fileData.content.arrayBuffer()));
    }
);

export const orderDetailStore = createSlice({
    name: 'orderDetail',
    initialState,
    reducers: {
        clearStore: () => {
            return {
                ...initialState,
                documents: initialState.documents,
                order: initialState.order,
                statusMessages: initialState.statusMessages
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOrderDetails.pending, (state: IOrderDetailState) => {
                state.status = 'loading';
            })
            .addCase(getOrderDetails.fulfilled, (state: IOrderDetailState, action: PayloadAction<IAmosOrderDetailsDto | undefined>) => {
                state.status = 'loaded';
                state.order = action.payload;
            })
            .addCase(getOrderDetails.rejected, (state: IOrderDetailState, error) => {
                state.status = 'failed';
            })
            .addCase(getOrderDocuments.pending, (state: IOrderDetailState) => {
                state.status = 'loading';
            })
            .addCase(getOrderDocuments.fulfilled, (state: IOrderDetailState, action: PayloadAction<IAmosDocumentsDto | undefined>) => {
                state.status = 'loaded';
                state.documents = action.payload;
            })
            .addCase(getOrderDocuments.rejected, (state: IOrderDetailState, error) => {
                state.status = 'failed';
            })
            .addCase(getOrderStatusMessages.pending, (state: IOrderDetailState) => {
                state.status = 'loading';
            })
            .addCase(getOrderStatusMessages.fulfilled, (state: IOrderDetailState, action: PayloadAction<IAmosLastStatusMessageListDto | undefined>) => {
                state.status = 'loaded';
                state.statusMessages = action.payload;
            })
            .addCase(getOrderStatusMessages.rejected, (state: IOrderDetailState, error) => {
                state.status = 'failed';
            })
    }
});

export const OrderDetailActions = orderDetailStore.actions;

export default orderDetailStore.reducer;