import NalModal from "@common/@components/NalModal";
import useAppTheme from "@core/appTheme";
import { useAppDispatch, useAppSelector } from "@core/hooks";
import { Box, Button, Checkbox, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useModalStyles } from "app/styles/styleExtension";
import { CreateOrderPricingStoreAction } from "createOrder/@store/createOrderPricingStore";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const OrderCarrierRate = () => {
    const theme = useAppTheme();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const modalClasses = useModalStyles();
    const [showModal, setShowModal] = useState<boolean>(false);

    const { rate, rateList, status, integrationErrors } = useAppSelector((state) => state.createOrderPricing);
    const [selectedRate, setSelectedRate] = useState(rate.selectedRateId || undefined);

    const selectRate = (carrierId: number) => {
        setSelectedRate(carrierId);
        dispatch(CreateOrderPricingStoreAction.setSelectedRate(carrierId));
    };

    const save = () => {
        navigate("..");
    }

    return (
        <>
            <Box
                sx={{
                    minWidth: "60vw",
                }}
            >
                <Box display="flex" alignItems="center" gap={3}>
                    <Typography variant="h5">Available Rates</Typography>
                    <Button color="info" variant="outlined" onClick={() => setShowModal(true)}>
                        Show Integration Errors
                    </Button>
                    <Button color="primary" variant="contained" onClick={save} disabled={!selectedRate}>
                        Save
                    </Button>
                </Box>
                {status === "loading" && (
                    <Box display="flex" justifyContent="center" p={4}>
                        <CircularProgress />
                    </Box>
                )}
                {status === "loaded" && rateList && rateList.length > 0 && (
                    <Table sx={{ marginTop: 4 }}>
                        <TableHead sx={{ backgroundColor: theme.palette.action.hover }}>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Total</TableCell>
                                <TableCell>Carrier Name</TableCell>
                                <TableCell>Quote Number</TableCell>
                                <TableCell>Mode</TableCell>
                                <TableCell>Service Type</TableCell>
                                <TableCell>Equipment</TableCell>
                                <TableCell>Transit Days</TableCell>
                                <TableCell>MC Number</TableCell>
                                <TableCell>Us DOT Number</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rateList.map((carrier, index) => (
                                <TableRow key={index}>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            color="success"
                                            checked={selectedRate === carrier.id}
                                            onChange={() => selectRate(carrier.id)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Box
                                            sx={{
                                                backgroundColor: theme.palette.info.dark,
                                                padding: "4px 7px",
                                                color: theme.palette.getContrastText(theme.palette.info.dark),
                                                borderRadius: "100px",
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Typography variant="body1" whiteSpace="nowrap">
                                                {carrier.total} {carrier.currency}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell>{carrier.carrierName}</TableCell>
                                    <TableCell>{carrier.quoteNumber}</TableCell>
                                    <TableCell>{carrier.transportationMode}</TableCell>
                                    <TableCell>{carrier.serviceType}</TableCell>
                                    <TableCell>{carrier.equipment}</TableCell>
                                    <TableCell>{carrier.transitDays}</TableCell>
                                    <TableCell>{carrier.mcNumber}</TableCell>
                                    <TableCell>{carrier.usDotNumber}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
                {(status === "loaded" && rateList && rateList.length === 0) && (
                    <Box display="flex" justifyContent="center" mt={4}>
                        <Typography variant="h6" whiteSpace="nowrap">
                            No rate was found
                        </Typography>
                    </Box>
                )}
            </Box>
            <NalModal
                open={showModal}
                onClose={() => setShowModal(false)}
                component={
                    <Box className={modalClasses.root} p={3} minWidth={300}>
                        <Typography variant="h6" mb={3}>Integration Errors</Typography>

                        {integrationErrors && Object.entries(integrationErrors).length > 0 && (
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Integrations Name</TableCell>
                                        <TableCell>Error Message</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.entries(integrationErrors).map(([integrationName, errorMessage]) => (
                                        <TableRow key={integrationName}>
                                            <TableCell>{integrationName}</TableCell>
                                            <TableCell>{errorMessage}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        ) || (
                                <Box display="flex" justifyContent="center">
                                    <Typography variant="subtitle1" whiteSpace="nowrap">
                                        No errors
                                    </Typography>
                                </Box>
                            )}
                    </Box>
                }
            />
        </>
    );
}

export default OrderCarrierRate;