import { StateKind } from "@common/state";
import { getServerAddress } from "@core/appVariables";
import { fetchClient } from "@core/fetchClient";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DistanceUOM, IAmosCarrierRateDto, IAmosCarrierRateRequestDto, IAmosCarrierRateResponseDto, WeightUOM } from "contracts/amos";

export interface IOrderContactInfo {
    email?: string;
    phone?: string;
    faxNumber?: string;
    comments?: string;
}

export interface IOrderRateInfo {
    totalDistance?: number;
    totalDistanceUOM?: DistanceUOM;
    totalWeight?: number;
    totalWeightUOM?: WeightUOM;
}

export interface IOrderRate {
    rateRequestGuid: string;
    selectedRateId: number;
    transitDays?: number;
}

interface IOrderPricingValidationState {
    contactInfo: boolean;
    visited: boolean;
}

interface ICreateOrderPricingState {
    status: StateKind;
    contactInfo: IOrderContactInfo;
    rateInfo: IOrderRateInfo;
    rate: IOrderRate;
    rateList: IAmosCarrierRateDto[] | undefined;
    integrationErrors?: { [key:string]: string };
    validation: IOrderPricingValidationState;
}

const initialState: ICreateOrderPricingState = {
    status: 'notLoaded',
    contactInfo: {},
    rateInfo: {},
    rate: {
        rateRequestGuid: '',
        selectedRateId: -1,
    },
    rateList: [],
    validation: {
        contactInfo: true,
        visited: false
    }
}

export const getCarrierRates = createAsyncThunk(
    'order/create/rates',
    async (dto: IAmosCarrierRateRequestDto) => {
        const response = await fetchClient().post<IAmosCarrierRateRequestDto, IAmosCarrierRateResponseDto>(`${getServerAddress('/orders/api')}/rate/order/create`, dto);

        return response;
    }
)

export const createOrderPricingStore = createSlice({
    name: 'CreateOrderPricingStore',
    initialState,
    reducers: {
        updateContactInfo: (state, action: PayloadAction<IOrderContactInfo>) => {
            state.contactInfo = action.payload;
        },
        setSelectedRate: (state, action: PayloadAction<number>) => {
            state.rate.selectedRateId = action.payload;

            const selectedRate = state.rateList?.find(x => x.id === action.payload);
            if (selectedRate) {
                state.rate.transitDays = selectedRate.transitDays;
            }
        },
        updatedValidation: (state, action: PayloadAction<IOrderPricingValidationState>) => {
            state.validation = action.payload;
        },
        clearStore: () => {
            return {
                ...initialState,
                contactInfo: { ...initialState.contactInfo },
                rateInfo: { ...initialState.rateInfo },
                rate: { ...initialState.rate },
                rateList: initialState.rateList ? [...initialState.rateList] : undefined,
                validation: { ...initialState.validation },
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCarrierRates.pending, (state: ICreateOrderPricingState) => {
                state.status = 'loading';
            })
            .addCase(getCarrierRates.fulfilled, (state: ICreateOrderPricingState, action: PayloadAction<IAmosCarrierRateResponseDto | undefined>) => {
                state.status = 'loaded';
                state.rateList = action.payload?.carrierRates;
                state.rate.rateRequestGuid = action.payload?.requestGuid || '';
                state.rateInfo = {
                    totalDistance: action.payload?.totalDistance,
                    totalDistanceUOM: action.payload?.totalDistanceUOM,
                    totalWeight: action.payload?.totalWeight,
                    totalWeightUOM: action.payload?.totalWeightUOM,
                }
                state.integrationErrors = action.payload?.integrationErrors;
            })
            .addCase(getCarrierRates.rejected, (state: ICreateOrderPricingState, error) => {
                state.status = 'failed';
            })
    }
})

export const CreateOrderPricingStoreAction = createOrderPricingStore.actions;
export default createOrderPricingStore.reducer;