import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchClient } from "@core/fetchClient";
import { addAppNotification } from "appNotification";
import { getServerAddress } from "@core/appVariables";
import { IAuthResetPasswordDto } from "contracts/auth";

export const resetPassword = createAsyncThunk(
    'auth/reset',
    async (dto: IAuthResetPasswordDto, trunk) => {
      await fetchClient().post<IAuthResetPasswordDto, void>(`${getServerAddress('/identity/api')}/auth/reset`, dto);
  
      trunk.dispatch(addAppNotification({
        kind: 'success',
        message: 'Password has been changed!',
      }));
    }
  )