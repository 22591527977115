import { INalFile } from "@common/nalFileManager";
import localStorageHelper from "./localstorageHelper";
import { AuthHelper } from "./authHelper";
import { getServerAddress } from "./appVariables";
import { IAuthRefreshTokenDto, IAuthResultDto } from "contracts/auth";

interface IFetchOptions {
    setToken?: boolean;
}

export const fetchClient = (originalFetch => (options: IFetchOptions = { setToken: true }) => {

    const send = async <TResult>(...args: [input: RequestInfo | URL, init?: RequestInit | undefined]) => {
        const controller = new AbortController();
        setTimeout(() => controller.abort(), 120000);
        args[1] = { ...args[1], signal: controller.signal };

        if (options.setToken) {
            const token = localStorageHelper.getAuthToken();

            if (token) {
                args[1].headers = { ...args[1].headers, 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };
            }
            else {
                args[1].headers = { ...args[1].headers, 'Content-Type': 'application/json' };
            }
        }


        const response = await originalFetch.apply(this, args);
        const text = await response.text();

        let result = {} as TResult;

        if (!response.ok) {
            if (response.status === 401) {
                if (await resetToken(controller.signal)) {
                    const token = localStorageHelper.getAuthToken();
                    if (token) {
                        args[1].headers = { ...args[1].headers, 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };
                    }

                    const response2 = await originalFetch.apply(this, args);
                    const text2 = await response2.text();

                    if (text2) {
                        result = JSON.parse(text2);
                    }
                }
                else {
                    throw response.status;
                }
            }
            else {
                if (text) {
                    throw JSON.parse(text);
                }
                else {
                    throw response.statusText || "Unknown error";
                }
            }
        }
        else {
            if (text) {
                result = JSON.parse(text);
            }
        }

        return result as TResult || undefined;
    };

    const resetToken = async (signal: AbortSignal) => {
        const refreshToken = localStorageHelper.getRefreshToken();
        const accessToken = localStorageHelper.getAuthToken();

        if (refreshToken) {
            const tokensResponse = await originalFetch.apply(this, [`${getServerAddress('/identity/api')}/auth${AuthHelper.refreshTokenPath}`,
            {
                method: 'POST',
                signal: signal,
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    accessToken: accessToken,
                    refreshToken: refreshToken
                } as IAuthRefreshTokenDto)
            }]);

            const text = await tokensResponse.text();

            if (tokensResponse.ok && text) {
                const tokens = JSON.parse(text) as IAuthResultDto;
                localStorageHelper.setAuthToken(tokens.accessToken);
                localStorageHelper.setRefreshToken(tokens.refreshToken);
                return true;
            }
        }

        localStorageHelper.removeAuthToken();
        localStorageHelper.removeRefreshToken();
        return false;
    }

    return {
        get: <TResult>(url: RequestInfo) => {
            return send<TResult>(url, { method: 'GET' });
        },
        post: <TRequest, TResponse>(url: RequestInfo, data?: TRequest) => {
            return send<TResponse>(url, { method: 'POST', body: JSON.stringify(data) });
        },
        put: <TResult>(url: RequestInfo, data?: any) => {
            return send<TResult>(url, { method: 'PUT', body: JSON.stringify(data) });
        },
        path: <TResult>(url: RequestInfo, data?: any) => {
            return send<TResult>(url, { method: 'PATCH', body: JSON.stringify(data) });
        },
        delete: <TResult>(url: RequestInfo, data?: any) => {
            return send<TResult>(url, { method: 'DELETE', body: JSON.stringify(data) });
        },
        stream: async (url: RequestInfo, method: 'GET' | 'POST', data?: any, contentType?: string, progress?: (status: { loaded: number, total: number }) => void): Promise<INalFile> => {
            const token = localStorageHelper.getAuthToken();
            const headers: HeadersInit = {
                'Authorization': `Bearer ${token}`,
            };

            if (contentType) {
                headers['Content-Type'] = contentType;

                if (contentType === 'application/json') {
                    data = JSON.stringify(data);
                }
            }

            const response = await originalFetch.call(this, url, { method, body: data, headers });
            if (!response.ok) {
                throw Error(response.status + ' ' + response.statusText);
            }

            // ensure ReadableStream is supported
            if (!response.body) {
                throw Error('ReadableStream not yet supported in this browser.');
            }

            // store the size of the entity-body, in bytes
            const contentLength = response.headers.get('content-length');
            // ensure contentLength is available
            if (!contentLength) {
                throw Error('Content-Length response header unavailable');
            }

            // parse the integer into a base-10 number
            const total = parseInt(contentLength, 10);
            let loaded = 0;

            // Get the filename from the Content-Disposition header
            const contentDisposition = response.headers.get('Content-Disposition');
            const filenameMatch = contentDisposition && contentDisposition.match(/filename=(.*?)(?=;|$)/);
            const filename = filenameMatch ? filenameMatch[1] : '';

            // Create an array to store chunks of the file content
            const dataArray: Uint8Array[] = [];

            // create a ReadableStream and consume it
            const reader = response.body.getReader();
            while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                if (value) {
                    dataArray.push(new Uint8Array(value));
                    loaded += value.byteLength;
                    progress && progress({ loaded, total });
                }
            }

            // All data has been loaded, resolve the promise with the complete INalFile object
            const completeData = dataArray.length ? new Uint8Array(dataArray.reduce((acc, chunk) => [...acc, ...Array.from(chunk)], [] as number[])) : new Uint8Array(0);
            const completeFile = new Blob([completeData], { type: response.headers.get('content-type') || '' });

            return {
                name: filename,
                contentType: completeFile.type,
                content: completeFile,
            };
        }
    }
})(fetch);