import { createSlice } from '@reduxjs/toolkit';

export interface IMapSettingsState {
  showPickUp: boolean;
  showDestination: boolean;
  showTracking: boolean;
}

const initialState: IMapSettingsState = {
  showPickUp: true,
  showDestination: true,
  showTracking: true,
};

export const mapSettingsStore = createSlice({
  name: 'mapSettings',
  initialState,
  reducers: {
    toggleShowPickUp: (state) => {
      state.showPickUp = !state.showPickUp;
    },
    toggleShowDestination: (state) => {
      state.showDestination = !state.showDestination;
    },
    toggleShowTracking: (state) => {
      state.showTracking = !state.showTracking;
    },
    clearStore: () => {
      return {
        showDestination: initialState.showDestination,
        showPickUp: initialState.showPickUp,
        showTracking: initialState.showTracking
      }
    }
  },
});

export const MapSettingsAction = mapSettingsStore.actions;

export default mapSettingsStore.reducer;