import { StateKind } from "@common/state";
import { getServerAddress } from "@core/appVariables";
import { fetchClient } from "@core/fetchClient";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IDashboardClaimCarrierDto, IDashboardClaimClosedDto, IDashboardClaimDetailsDto, IDashboardClaimShipDateDto, IDashboardClaimSummaryDto, IDashboardKpiBaseFilterDto } from "contracts/dashboard";
import { IReportBaseFilterDto } from "contracts/report";


interface IClaimsStoreStatus {
    status: StateKind;
    claimsClosed?: IDashboardClaimClosedDto[];
    summary?: IDashboardClaimSummaryDto;
    shipDate: IDashboardClaimShipDateDto[];
    details?: IDashboardClaimDetailsDto;
    carrier: IDashboardClaimCarrierDto[];
}

const initialState: IClaimsStoreStatus = {
    status: 'notLoaded',
    claimsClosed: undefined,
    shipDate: [],
    carrier: [],
}

export const getClaimsClosed = createAsyncThunk(
    'claims-dashboard/closed',
    async (request: IDashboardKpiBaseFilterDto) => {
        const response = await fetchClient().post<IDashboardKpiBaseFilterDto, IDashboardClaimClosedDto[]>(`${getServerAddress('/analytics/api')}/claims/closed/reason`, request);

        return response;
    }
)

export const getClaimsSummary = createAsyncThunk(
    'claims-dashboard/summary',
    async (request: IDashboardKpiBaseFilterDto) => {
        const response = await fetchClient().post<IDashboardKpiBaseFilterDto, IDashboardClaimSummaryDto>(`${getServerAddress('/analytics/api')}/claims/summary`, request);

        return response;
    }
)

export const getClaimsShipDate = createAsyncThunk(
    'claims-dashboard/ship-date',
    async (request: IDashboardKpiBaseFilterDto) => {
        const response = await fetchClient().post<IDashboardKpiBaseFilterDto, IDashboardClaimShipDateDto[]>(`${getServerAddress('/analytics/api')}/claims/date`, request);

        return response;
    }
)

export const getClaimsDetails = createAsyncThunk(
    'claims-dashboard/details',
    async (request: IReportBaseFilterDto) => {
        const response = await fetchClient().post<IReportBaseFilterDto, IDashboardClaimDetailsDto>(`${getServerAddress('/analytics/api')}/claims/details`, request);

        return response;
    }
)

export const getClaimsCarrier= createAsyncThunk(
    'claims-dashboard/carrier',
    async (request: IDashboardKpiBaseFilterDto) => {
        const response = await fetchClient().post<IDashboardKpiBaseFilterDto, IDashboardClaimCarrierDto[]>(`${getServerAddress('/analytics/api')}/claims/carrier`, request);

        return response;
    }
)

export const claimsStore = createSlice({
    name: 'claims-dashboard',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getClaimsClosed.pending, (state: IClaimsStoreStatus) => {
                state.status = 'loading';
            })
            .addCase(getClaimsClosed.fulfilled, (state: IClaimsStoreStatus, action: PayloadAction<IDashboardClaimClosedDto[] | undefined>) => {
                state.claimsClosed = action.payload;
                state.status = 'loaded';
            })
            .addCase(getClaimsClosed.rejected, (state: IClaimsStoreStatus, error) => {
                state.status = 'failed';
            })
            .addCase(getClaimsSummary.pending, (state: IClaimsStoreStatus) => {
                state.status = 'loading';
            })
            .addCase(getClaimsSummary.fulfilled, (state: IClaimsStoreStatus, action: PayloadAction<IDashboardClaimSummaryDto | undefined>) => {
                state.summary = action.payload;
                state.status = 'loaded';
            })
            .addCase(getClaimsSummary.rejected, (state: IClaimsStoreStatus, error) => {
                state.status = 'failed';
            })
            .addCase(getClaimsShipDate.pending, (state: IClaimsStoreStatus) => {
                state.status = 'loading';
            })
            .addCase(getClaimsShipDate.fulfilled, (state: IClaimsStoreStatus, action: PayloadAction<IDashboardClaimShipDateDto[] | undefined>) => {
                state.shipDate = action.payload || [];
                state.status = 'loaded';
            })
            .addCase(getClaimsShipDate.rejected, (state: IClaimsStoreStatus, error) => {
                state.status = 'failed';
            })
            .addCase(getClaimsDetails.pending, (state: IClaimsStoreStatus) => {
                state.status = 'loading';
            })
            .addCase(getClaimsDetails.fulfilled, (state: IClaimsStoreStatus, action: PayloadAction<IDashboardClaimDetailsDto | undefined>) => {
                state.details = action.payload;
                state.status = 'loaded';
            })
            .addCase(getClaimsDetails.rejected, (state: IClaimsStoreStatus, error) => {
                state.status = 'failed';
            })
            .addCase(getClaimsCarrier.pending, (state: IClaimsStoreStatus) => {
                state.status = 'loading';
            })
            .addCase(getClaimsCarrier.fulfilled, (state: IClaimsStoreStatus, action: PayloadAction<IDashboardClaimCarrierDto[] | undefined>) => {
                state.carrier = action.payload || [];
                state.status = 'loaded';
            })
            .addCase(getClaimsCarrier.rejected, (state: IClaimsStoreStatus, error) => {
                state.status = 'failed';
            })
            
    }
});

export const KpiAction = claimsStore.actions;

export default claimsStore.reducer;