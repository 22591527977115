import { StyledEngineProvider } from '@mui/material';
import { PropsWithChildren, useEffect } from 'react';
import LayoutContent from './LayoutContent';
import { AppNotificationBar } from 'appNotification';
import { Outlet } from 'react-router-dom';

export function Layout({ children }: PropsWithChildren) {

  return (
    <StyledEngineProvider injectFirst>
      <AppNotificationBar />
      <LayoutContent>{children}</LayoutContent>

      <Outlet />

    </StyledEngineProvider>
  );
}
