import Link from "@mui/material/Link";
import { PropsWithChildren, forwardRef } from "react";
import { LinkProps, NavLink } from "react-router-dom";

interface NALNavLinkProps extends PropsWithChildren<LinkProps> {
  to: string;
  href?: string;
}

const NALNavLink = forwardRef<HTMLAnchorElement, NALNavLinkProps>((props, ref) => {
  const { to, href, ...otherProps } = props;
  return (
    <Link ref={ref} to={href || to} component={NavLink} {...otherProps}>
      {props.children}
    </Link>
  );
});

export default NALNavLink;