import { Box, Button, Typography } from "@mui/material";
import NalModal from "./NalModal";
import { useModalStyles } from "app/styles/styleExtension";

interface INalDeleteConfirmModelProps {
    onSubmit: (confirm: boolean) => void;
    setHideModal: () => void;
    showModal: boolean;
    title: string;
    description: string;
}

const NalDeleteConfirmModel = (props: INalDeleteConfirmModelProps) => {
    const modalClasses = useModalStyles();

    const onCancel = () => {
        props.setHideModal();
    }

    const onDelete = () => {
        props.onSubmit(true)
        props.setHideModal();
    }

    return (
        <NalModal
            open={props.showModal}
            onClose={() => props.setHideModal()}
            component={
                <Box className={modalClasses.root} p={3} minWidth={450} display='flex' flexDirection='column' gap={3}>
                    <Typography variant="h5">{props.title}</Typography>
                    <Typography>{props.description}</Typography>
                    <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
                        <Button color="secondary" onClick={() => onCancel()}>Cancel</Button>
                        <Button color="secondary" onClick={() => onDelete()}>Delete</Button>
                    </Box>
                </Box>
            }
        />
    )
}

export default NalDeleteConfirmModel;