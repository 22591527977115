import { StateKind } from "@common/state";
import { getServerAddress } from "@core/appVariables";
import { fetchClient } from "@core/fetchClient";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IAmosReferenceCreateDto, IAmosReferenceUpdateDto, IAmosReferenceDto, IAmosReferenceTypes, IAmosReferencesDto } from "contracts/amos";

interface IOrderDetailReferencesState {
    status: StateKind;
    references: IAmosReferenceDto[] | undefined;
    referencesTypes: IAmosReferenceTypes | undefined;
    showCreate: boolean;
}

const initialState: IOrderDetailReferencesState = {
    status: 'notLoaded',
    references: [],
    referencesTypes: undefined,
    showCreate: false,
}

export const getOrderReference = createAsyncThunk(
    'details/{orderGuid}/references',
    async (orderGuid: string) => {
        const response = await fetchClient().get<IAmosReferencesDto>(`${getServerAddress('/orders/api')}/references/order/${orderGuid}`);

        return response;
    }
);

export const getReferenceTypes = createAsyncThunk(
    'references/{branchGuid}',
    async (branchGuid: string) => {
        const response = await fetchClient().get<IAmosReferenceTypes>(`${getServerAddress('/orders/api')}/references/types/${branchGuid}`);

        return response;
    }
);

export const createReference = createAsyncThunk(
    'details/reference/create',
    async (dto: IAmosReferenceCreateDto) => {
        const response = await fetchClient().post<IAmosReferenceCreateDto, IAmosReferenceDto>(`${getServerAddress('/orders/api')}/references`, dto);

        return response;
    }
);

export const updateReference = createAsyncThunk(
    'details/reference/update',
    async (dto: IAmosReferenceUpdateDto) => {
        const response = await fetchClient().put<IAmosReferenceDto>(`${getServerAddress('/orders/api')}/references`, dto);

        return response;
    }
);

export const deleteReference = createAsyncThunk(
    'details/reference/delete',
    async (referenceGuid: string) => {
        await fetchClient().delete(`${getServerAddress('/orders/api')}/references/${referenceGuid}`);

        return referenceGuid;
    }
);

export const orderReferencesStore = createSlice({
    name: 'orderDetailReferences',
    initialState,
    reducers: {
        showCreateReference: (state) => {
            state.showCreate = !state.showCreate;
        },
        clearStore: () => {
            return {
                ...initialState,
                references: initialState.references,
                referencesTypes: initialState.referencesTypes,
                showCreate: initialState.showCreate
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOrderReference.pending, (state: IOrderDetailReferencesState) => {
                state.status = 'loading';
            })
            .addCase(getOrderReference.fulfilled, (state: IOrderDetailReferencesState, action: PayloadAction<IAmosReferencesDto | undefined>) => {
                state.status = 'loaded';
                state.references = action.payload?.elements;
            })
            .addCase(getOrderReference.rejected, (state: IOrderDetailReferencesState, error) => {
                state.status = 'failed';
            })
            .addCase(deleteReference.pending, (state: IOrderDetailReferencesState) => {
                state.status = 'loading';
            })
            .addCase(deleteReference.fulfilled, (state: IOrderDetailReferencesState, action: PayloadAction<string>) => {
                state.status = 'loaded';
                state.references = state.references?.filter(reference => reference.guid !== action.payload);
            })
            .addCase(deleteReference.rejected, (state: IOrderDetailReferencesState, error) => {
                state.status = 'failed';
            })
            .addCase(createReference.pending, (state: IOrderDetailReferencesState) => {
                state.status = 'loading';
            })
            .addCase(createReference.fulfilled, (state: IOrderDetailReferencesState, action: PayloadAction<IAmosReferenceDto | undefined>) => {
                state.status = 'loaded';
                
                if (action.payload) {
                    state.references = state.references ? [...state.references, action.payload] : [action.payload];
                }
            })
            .addCase(createReference.rejected, (state: IOrderDetailReferencesState, error) => {
                state.status = 'failed';
            })
            .addCase(updateReference.pending, (state: IOrderDetailReferencesState) => {
                state.status = 'loading';
            })
            .addCase(updateReference.fulfilled, (state: IOrderDetailReferencesState, action: PayloadAction<IAmosReferenceDto | undefined>) => {
                state.status = 'loaded';
                
                if (action.payload) {
                    state.references = state.references?.map(reference =>
                        reference.guid === action.payload!.guid ? action.payload! : reference
                    );
                }
            })
            .addCase(updateReference.rejected, (state: IOrderDetailReferencesState, error) => {
                state.status = 'failed';
            })
            .addCase(getReferenceTypes.pending, (state: IOrderDetailReferencesState) => {
                state.status = 'loading';
            })
            .addCase(getReferenceTypes.fulfilled, (state: IOrderDetailReferencesState, action: PayloadAction<IAmosReferenceTypes | undefined>) => {
                state.status = 'loaded';
                state.referencesTypes = action.payload;
            })
            .addCase(getReferenceTypes.rejected, (state: IOrderDetailReferencesState, error) => {
                state.status = 'failed';
            })
    }
});

export const OrderReferencesActions = orderReferencesStore.actions;

export default orderReferencesStore.reducer;