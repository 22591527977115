//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export interface ICommonListDto
{
	storedValue: string;
	displayedValue: string;
}
export enum StatusEnum {
	DELIVERED = 1,
	CANCELED = 2,
	PLANNED = 3,
	BOOKED = 4,
	IN_TRANSIT = 5
}
export enum SortOrderEnum {
	Asc = 0,
	Desc = 1
}
