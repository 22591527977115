import { StateKind } from "@common/state";
import { getServerAddress } from "@core/appVariables";
import { fetchClient } from "@core/fetchClient";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDashboardKpiStatusFilterDto, IDashboardOnTimeDto } from "contracts/dashboard";
import { IPerformanceTransportByModeDto, IPerformanceTransportShipmentsDto } from "contracts/performance";


interface IPerformanceTransportState {
    status: StateKind;
    byMode?: IPerformanceTransportByModeDto[];
    shipments?: IPerformanceTransportShipmentsDto[];
    onTime?: IDashboardOnTimeDto;
}

const initialState: IPerformanceTransportState = {
    status: 'notLoaded'
}

export const getByMode = createAsyncThunk(
    'performance-transport/getByMode',
    async (request: IDashboardKpiStatusFilterDto) => {
        const response = await fetchClient().post<IDashboardKpiStatusFilterDto, IPerformanceTransportByModeDto[]>(`${getServerAddress('/analytics/api')}/performance-transport/spend-by-mode`, request);

        return response;
    }
)

export const getShipments = createAsyncThunk(
    'performance-transport/shipments',
    async (request: IDashboardKpiStatusFilterDto) => {
        const response = await fetchClient().post<IDashboardKpiStatusFilterDto, IPerformanceTransportShipmentsDto[]>(`${getServerAddress('/analytics/api')}/performance-transport/shipments`, request);

        return response;
    }
)

export const getOnTime = createAsyncThunk(
    'kpi-dashboard/common/on-time',
    async (request: IDashboardKpiStatusFilterDto) => {
        const response = await fetchClient().post<IDashboardKpiStatusFilterDto, IDashboardOnTimeDto>(`${getServerAddress('/analytics/api')}/performance-transport/on-time`, request);

        return response;
    }
)

export const performanceTransportStore = createSlice({
    name: 'performance-transport',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getByMode.pending, (state: IPerformanceTransportState) => {
                state.status = 'loading';
            })
            .addCase(getByMode.fulfilled, (state: IPerformanceTransportState, action: PayloadAction<IPerformanceTransportByModeDto[] | undefined>) => {
                state.byMode = action.payload;
                state.status = 'loaded';
            })
            .addCase(getByMode.rejected, (state: IPerformanceTransportState, error) => {
                state.status = 'failed';
            })
            .addCase(getShipments.pending, (state: IPerformanceTransportState) => {
                state.status = 'loading';
            })
            .addCase(getShipments.fulfilled, (state: IPerformanceTransportState, action: PayloadAction<IPerformanceTransportShipmentsDto[] | undefined>) => {
                state.shipments = action.payload;
                state.status = 'loaded';
            })
            .addCase(getShipments.rejected, (state: IPerformanceTransportState, error) => {
                state.status = 'failed';
            })
            .addCase(getOnTime.pending, (state: IPerformanceTransportState) => {
                state.status = 'loading';
            })
            .addCase(getOnTime.fulfilled, (state: IPerformanceTransportState, action: PayloadAction<IDashboardOnTimeDto | undefined>) => {
                state.onTime = action.payload;
                state.status = 'loaded';
            })
            .addCase(getOnTime.rejected, (state: IPerformanceTransportState, error) => {
                state.status = 'failed';
            })
    }
});

export const PerformanceTransportAction = performanceTransportStore.actions;

export default performanceTransportStore.reducer;
