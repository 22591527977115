import { downloadFile } from "@common/nalFileManager";
import { getServerAddress } from "@core/appVariables";
import { fetchClient } from "@core/fetchClient";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IReportStatusFilterDto, IReportWpdDetailsDto, IReportWpdSummaryDetailDto } from "contracts/report";

export const getWpdReportSummary = createAsyncThunk(
    'reports/wpd/summary',
    async (request: IReportStatusFilterDto) => {
        const response = await fetchClient().post<IReportStatusFilterDto, IReportWpdSummaryDetailDto>(`${getServerAddress('/analytics/api')}/wpd/summary`, request);

        return response;
    }
)

export const getWpdReportDetails = createAsyncThunk(
    'reports/wpd/details',
    async (request: IReportStatusFilterDto) => {
        const response = await fetchClient().post<IReportStatusFilterDto, IReportWpdDetailsDto>(`${getServerAddress('/analytics/api')}/wpd/details`, request);

        return response;
    }
)

export const downloadWpdDetailAsExcel = createAsyncThunk(
    'reports/wpd/{branchGuid}/xml',
    async (request: IReportStatusFilterDto) => {
        const fileData = await fetchClient().stream(`${getServerAddress('/analytics/api')}/wpd/export`, "POST", request, 'application/json');

        downloadFile(fileData.name, fileData.contentType, new Uint8Array(await fileData.content.arrayBuffer()));
    }
);