import { downloadFile } from "@common/nalFileManager";
import { getServerAddress } from "@core/appVariables";
import { fetchClient } from "@core/fetchClient";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IReportAdditionalChargesDto, IReportTextFilterDto } from "contracts/report";


export const getAdditionalChargesReportDetail = createAsyncThunk(
    'reports/additionalCharges/details',
    async (request: IReportTextFilterDto) => {
        const response = await fetchClient().post<IReportTextFilterDto, IReportAdditionalChargesDto>(`${getServerAddress('/analytics/api')}/additionalCharges/details`, request);

        return response;
    }
)

export const downloadAdditionalChargesDetailAsExcel = createAsyncThunk(
    'reports/additionalCharges/{branchGuid}/xml',
    async (request: IReportTextFilterDto) => {
        const fileData = await fetchClient().stream(`${getServerAddress('/analytics/api')}/additionalCharges/export`, "POST", request, 'application/json');

        downloadFile(fileData.name, fileData.contentType, new Uint8Array(await fileData.content.arrayBuffer()));
    }
);