import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useFullWidth = makeStyles(() => ({
    root: {
        width: "100%"
    },
    extended: {
        width: "225%"
    },
    half: {
        width: "50%"
    },
    autocomplete: {
        minWidth: 300
    }
}));

export const useCustomButtons = makeStyles(() => ({
    clear: {
        position: 'absolute',
        right: 35,
        top: 20,
        transform: 'translateY(-50%)',
    },
    clearLarge: {
        top: 28,
    }
}));

export const useModalStyles = makeStyles((theme: Theme) => ({
    root: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: 4,
        backgroundColor: theme.palette.background.paper,
    },
}));

