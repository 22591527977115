import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material";
import useAppTheme from "@core/appTheme";
import { ReactNode } from "react";

interface INalCardProps {
    title: string;
    icon?: () => JSX.Element;
    iconAlignRight?: boolean;
    content: ReactNode;
    maxHeight?: number;
    action?: ReactNode;
}

const NalCard = (props: INalCardProps) => {
    const theme = useAppTheme();

    return (
        <Card sx={{
            height: '100%',
            minWidth: 350
        }}
        >
            <CardHeader
                sx={{
                    backgroundColor: theme.palette.action.focus,
                }}
                title={
                    <Box display="flex" alignItems="center" gap={1}
                        sx={{
                            flexDirection: props.iconAlignRight ? "row-reverse" : "row",
                            justifyContent: props.iconAlignRight ? "flex-end" : "flex-start"
                        }}
                    >
                        {props.icon && props.icon()}
                        <Typography variant="h6">{props.title}</Typography>
                    </Box>
                }
                action={
                    <>
                        {props.action}
                    </>
                }
            />
            <CardContent sx={{
                overflowY: "auto",
                overflowX: "hidden",
                maxHeight: props.maxHeight ? props.maxHeight : 500,
            }}>
                {props.content}
            </CardContent>
        </Card>
    );
}

export default NalCard;