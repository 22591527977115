import { StateKind } from "@common/state";
import { getServerAddress } from "@core/appVariables";
import { fetchClient } from "@core/fetchClient";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDashboardKpiBaseFilterDto } from "contracts/dashboard";
import { IPerformanceQuantityDto } from "contracts/performance";


interface IPerformanceQuantityState {
    status: StateKind;
    quantities?: IPerformanceQuantityDto[];
}

const initialState: IPerformanceQuantityState = {
    status: 'notLoaded'
}

export const getQuantities = createAsyncThunk(
    'performance-quantity/get',
    async (request: IDashboardKpiBaseFilterDto) => {
        const response = await fetchClient().post<IDashboardKpiBaseFilterDto, IPerformanceQuantityDto[]>(`${getServerAddress('/analytics/api')}/performance-quantity`, request);

        return response;
    }
)

export const performanceQuantityStore = createSlice({
    name: 'performance-quantity',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getQuantities.pending, (state: IPerformanceQuantityState) => {
                state.status = 'loading';
            })
            .addCase(getQuantities.fulfilled, (state: IPerformanceQuantityState, action: PayloadAction<IPerformanceQuantityDto[] | undefined>) => {
                state.quantities = action.payload;
                state.status = 'loaded';
            })
            .addCase(getQuantities.rejected, (state: IPerformanceQuantityState, error) => {
                state.status = 'failed';
            })
    }
});

export const PerformanceQuantityAction = performanceQuantityStore.actions;

export default performanceQuantityStore.reducer;
