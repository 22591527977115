import { useAppSelector } from '@core/hooks';
import { anyReportsAccess, createOrderAccess, selectAccessInfo, viewRateAccess } from '@core/permissionsHelper';
import { selectAuth } from 'auth/logic/selectors';
import { Navigate } from 'react-router-dom';


export const RouteGuard = (props: { children: JSX.Element, loginPath: string }) => {
    const { isLoggedIn } = useAppSelector(selectAuth);

    return isLoggedIn ? props.children : <Navigate to={props.loginPath} />;
};

export const CreateOrderRouteGuard = (props: { children: JSX.Element, homePath: string }) => {
    const accessInfo = useAppSelector((state) => selectAccessInfo(state));

    return createOrderAccess(accessInfo) ? props.children : <Navigate to={props.homePath} />;
};

export const ReportsRouteGuard = (props: { children: JSX.Element, homePath: string }) => {
    const accessInfo = useAppSelector((state) => selectAccessInfo(state));

    return anyReportsAccess(accessInfo) ? props.children : <Navigate to={props.homePath} />;
};

export const ViewRateGuard = (props: { children: JSX.Element, homePath: string }) => {
    const accessInfo = useAppSelector((state) => selectAccessInfo(state));

    return viewRateAccess(accessInfo) ? props.children : <Navigate to={props.homePath} />;
}