import { StateKind } from "@common/state";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface IOrderAdditionalInfo {
    services?: string[],
    equipment?: string,
    orderType?: string,
    specialInstructions?: string;
}

export interface IOrderReference {
    typeGuid: string;
    typeName: string;
    value: string;
    identifier: string;
}

export interface IOrderReferenceValidation {
    type?: string,
    value?: string,
    identifier?: string;
}

interface IOrderInfoValidationState {
    references?: boolean,
    visited: boolean
}

interface ICreateOrderInfoState {
    status: StateKind;
    info: IOrderAdditionalInfo;
    hot: boolean;
    references?: IOrderReference[];
    referencesValidation?: IOrderReferenceValidation[];
    validations: IOrderInfoValidationState;
}

const initialState: ICreateOrderInfoState = {
    status: "notLoaded",
    info: {},
    hot: false,
    references: [],
    referencesValidation: [],
    validations: {
        visited: false,
        references: true
    }
}

export const createOrderInfoStore = createSlice({
    name: 'CreateOrderAdditionalInfoStore',
    initialState,
    reducers: {
        updatedInfo: (state, action: PayloadAction<IOrderAdditionalInfo>) => {
            state.info = {
                ...state.info,
                ...action.payload,
            };
        },
        setHot: (state, action: PayloadAction<{ hot: boolean }>) => {
            state.hot = action.payload.hot;
        },
        updateValidation: (state, action: PayloadAction<IOrderInfoValidationState>) => {
            state.validations = {
                ...state.validations,
                ...action.payload,
            };
        },
        addReference: (state, action: PayloadAction<IOrderReference>) => {
            const references = [...(state.references || [])];
            references.push(action.payload);

            const referencesValidation = [...(state.referencesValidation || [])]
            referencesValidation.push({
                type: '',
                value: '',
                identifier: action.payload.identifier
            })

            return {
                ...state,
                references,
                referencesValidation
            };
        },
        deleteReference: (state, action: PayloadAction<string>) => {
            const identifierToDelete = action.payload;
            state.references = state.references?.filter((ref) => ref.identifier !== identifierToDelete);
            state.referencesValidation = state.referencesValidation?.filter((ref) => ref.identifier !== identifierToDelete);
        },
        updateReference: (state, action: PayloadAction<IOrderReference>) => {
            const updatedReference = action.payload;
            const indexToUpdate = state.references?.findIndex((reference) => reference.identifier === updatedReference.identifier);

            if (indexToUpdate !== -1) {
                state.references![indexToUpdate!] = updatedReference;
            }
        },
        updateReferencesValidation: (state, action: PayloadAction<IOrderReferenceValidation>) => {
            const updatedValidation = action.payload;
            const indexToUpdate = state.referencesValidation?.findIndex((validation) => validation.identifier === updatedValidation.identifier);

            if (indexToUpdate !== -1) {
                state.referencesValidation![indexToUpdate!] = updatedValidation;
            }
        },
        validateReference: (state) => {
            const hasInvalidReference = state.references?.some((reference) => {
                return !reference.typeGuid || !reference.value;
            });
        
            state.validations.references = !hasInvalidReference;
            state.validations.visited = true;

            const validationState = state.references?.map((reference) => ({
                identifier: reference.identifier,
                type: !reference.typeGuid ? 'Type is required' : '',
                value: !reference.value ? 'Value is required' : '',
            }));
        
            state.referencesValidation = validationState;
        },
        clearStore: () => {
            return {
                ...initialState,
                references: initialState.references?.map(ref => ({ ...ref })),
                referencesValidation: initialState.referencesValidation?.map(val => ({ ...val })),
            };
        },
        resetAdditionalInfo: (state) => {
            state.info = {
                ...initialState.info,
                specialInstructions: state.info.specialInstructions,
            };
            state.references = initialState.references;
            state.referencesValidation = initialState.referencesValidation;
            state.validations = initialState.validations;
        }
    }
})

export const CreateOrderInfoStoreAction = createOrderInfoStore.actions;
export default createOrderInfoStore.reducer;