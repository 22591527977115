import { fetchClient } from "@core/fetchClient";
import { IAmosMapLocationDto } from "contracts/amos";

export function isValidLocation(loc?: IAmosMapLocationDto) {
    return loc?.latitude && loc.longitude;
};

export async function getMapRouteCoordinate(start: number[], end: number[]) {
    const response = await fetchClient({ setToken: false }).get<any>(`https://router.project-osrm.org/route/v1/driving/${start[1]},${start[0]};${end[1]},${end[0]}?geometries=geojson`);

    const routeData = response?.routes[0]?.geometry?.coordinates || [];
    return routeData
} 