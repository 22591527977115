import { StateKind } from '@common/state';
import { fetchClient } from '@core/fetchClient';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getServerAddress } from '@core/appVariables';
import { ReportTypeEnum } from 'contracts/amos';
import { downloadApgDetailAsExcel, getApgReportDetail } from 'reports/@components/apg/apgRequests';
import { getLdtReportDetail } from 'reports/@components/ldt/ldtRequests';
import { IReportType } from 'reports/@core/reportTypeList';
import { IReportAccesoriesDetailsDto, IReportAdditionalChargesDto, IReportApgDetailsDto, IReportBranchFilterDto, IReportBranchLookupDto, IReportExpediteDetailsDto, IReportGLCodeDetailsDto, IReportLdtDetailsDto, IReportOrderAssessorialDetailsDto, IReportSfsDetailsDto, IReportSpendDetailsDto, IReportWpdDetailsDto, IReportWpdSummaryDetailDto } from 'contracts/report';
import { downloadSfsDetailAsExcel, getSfsReportDetail } from 'reports/@components/sfs/sfsRequests';
import { downloadWpdDetailAsExcel, getWpdReportDetails, getWpdReportSummary } from 'reports/@components/wpd/wpdRequests';
import { downloadAdditionalChargesDetailAsExcel, getAdditionalChargesReportDetail } from 'reports/@components/additionalCharges/additionalChargesRequests';
import { downloadOrderAssessorialDetailAsExcel, getAdditionalChargesNames, getOrderAssessorialReportDetail } from 'reports/@components/orderDetailsAssessorial/orderAssessorialRequests';
import { downloadSpendDetailAsExcel, getSpendReportDetail } from 'reports/@components/spend/spendRequests';
import { downloadAccesoriesDetailAsExcel, getAccesoriesReportDetail } from 'reports/@components/accesories/accesoriesRequests';
import { downloadExpediteDetailAsExcel, getExpediteReportDetail } from 'reports/@components/Expedite/ExpediteRequests';
import { downloadGLCodeDetailAsExcel, getGLCodeReportDetail } from 'reports/@components/GLCode/GLCodeRequests';

export interface IBranchSearch {
    branches?: string[];
    page: number;
    rowsPerPage: number;
}

export interface IReportState {
    status: StateKind;
    additionalStatus: StateKind;
    downloadStatus: StateKind;

    branches?: IReportBranchLookupDto;
    apgDetails?: IReportApgDetailsDto;
    ltdDetails?: IReportLdtDetailsDto;
    sfsDetails?: IReportSfsDetailsDto;
    wpdSummary?: IReportWpdSummaryDetailDto;
    wpdDetails?: IReportWpdDetailsDto;
    additionalChargesDetails?: IReportAdditionalChargesDto;
    orderAssessorialDetails?: IReportOrderAssessorialDetailsDto;
    spendDetails?: IReportSpendDetailsDto;
    accesoriesDetails?: IReportAccesoriesDetailsDto;
    expediteDetails?: IReportExpediteDetailsDto;
    gLCodeDetails?: IReportGLCodeDetailsDto;
    chargeNames?: string[]; 

    selectedType: ReportTypeEnum | undefined;
    availableReports: IReportType[];
}

const initialState: IReportState = {
    status: "notLoaded",
    additionalStatus: 'notLoaded',
    downloadStatus: "notLoaded",

    selectedType: undefined,
    availableReports: [],
};

export const getBranches = createAsyncThunk(
    'reports/branches',
    async (request: IReportBranchFilterDto) => {
        const response = await fetchClient().post<IReportBranchFilterDto, IReportBranchLookupDto>(`${getServerAddress('/analytics/api')}/branch/list`, request);

        return response;
    }
)

export const reportsStore = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        changeReportType: (state, action: PayloadAction<ReportTypeEnum>) => {
            state.selectedType = action.payload;
        },
        setAvailableReports: (state, action: PayloadAction<IReportType[]>) => {
            state.availableReports = action.payload;
        },
        clearStore: () => {
            return {
                ...initialState,
                apgDetails: initialState.apgDetails,
                branches: initialState.branches,
                ltdDetails: initialState.ltdDetails,
                selectedType: initialState.selectedType 
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBranches.pending, (state: IReportState) => {
                state.status = 'loading';
            })
            .addCase(getBranches.fulfilled, (state: IReportState, action: PayloadAction<IReportBranchLookupDto | undefined>) => {
                state.status = 'loaded';
                state.branches = action.payload;
            })
            .addCase(getBranches.rejected, (state: IReportState, error) => {
                state.status = 'failed';
            })
            .addCase(getApgReportDetail.pending, (state: IReportState) => {
                state.status = 'loading';
            })
            .addCase(getApgReportDetail.fulfilled, (state: IReportState, action: PayloadAction<IReportApgDetailsDto | undefined>) => {
                state.status = 'loaded';
                state.apgDetails = action.payload;
            })
            .addCase(getApgReportDetail.rejected, (state: IReportState, error) => {
                state.status = 'failed';
            })
            .addCase(getLdtReportDetail.pending, (state: IReportState) => {
                state.status = 'loading';
            })
            .addCase(getLdtReportDetail.fulfilled, (state: IReportState, action: PayloadAction<IReportLdtDetailsDto | undefined>) => {
                state.status = 'loaded';
                state.ltdDetails = action.payload;
            })
            .addCase(getLdtReportDetail.rejected, (state: IReportState, error) => {
                state.status = 'failed';
            })
            .addCase(downloadApgDetailAsExcel.pending, (state: IReportState) => {
                state.downloadStatus = 'loading';
            })
            .addCase(downloadApgDetailAsExcel.fulfilled, (state: IReportState) => {
                state.downloadStatus = 'loaded';
            })
            .addCase(downloadApgDetailAsExcel.rejected, (state: IReportState, error) => {
                state.downloadStatus = 'failed';
            })
            .addCase(getSfsReportDetail.pending, (state: IReportState) => {
                state.status = 'loading';
            })
            .addCase(getSfsReportDetail.fulfilled, (state: IReportState, action: PayloadAction<IReportSfsDetailsDto | undefined>) => {
                state.sfsDetails = action.payload;
                state.status = 'loaded';
            })
            .addCase(getSfsReportDetail.rejected, (state: IReportState, error) => {
                state.status = 'failed';
            })
            .addCase(downloadSfsDetailAsExcel.pending, (state: IReportState) => {
                state.downloadStatus = 'loading';
            })
            .addCase(downloadSfsDetailAsExcel.fulfilled, (state: IReportState) => {
                state.downloadStatus = 'loaded';
            })
            .addCase(downloadSfsDetailAsExcel.rejected, (state: IReportState, error) => {
                state.downloadStatus = 'failed';
            })
            .addCase(getWpdReportSummary.pending, (state: IReportState) => {
                state.status = 'loading';
            })
            .addCase(getWpdReportSummary.fulfilled, (state: IReportState, action: PayloadAction<IReportWpdSummaryDetailDto | undefined>) => {
                state.wpdSummary = action.payload;
                state.status = 'loaded';
            })
            .addCase(getWpdReportSummary.rejected, (state: IReportState, error) => {
                state.status = 'failed';
            })
            .addCase(getWpdReportDetails.pending, (state: IReportState) => {
                state.additionalStatus = 'loading';
            })
            .addCase(getWpdReportDetails.fulfilled, (state: IReportState, action: PayloadAction<IReportWpdDetailsDto | undefined>) => {
                state.wpdDetails = action.payload;
                state.additionalStatus = 'loaded';
            })
            .addCase(getWpdReportDetails.rejected, (state: IReportState, error) => {
                state.additionalStatus = 'failed';
            })
            .addCase(downloadWpdDetailAsExcel.pending, (state: IReportState) => {
                state.downloadStatus = 'loading';
            })
            .addCase(downloadWpdDetailAsExcel.fulfilled, (state: IReportState) => {
                state.downloadStatus = 'loaded';
            })
            .addCase(downloadWpdDetailAsExcel.rejected, (state: IReportState, error) => {
                state.downloadStatus = 'failed';
            })
            .addCase(getAdditionalChargesReportDetail.pending, (state: IReportState) => {
                state.status = 'loading';
            })
            .addCase(getAdditionalChargesReportDetail.fulfilled, (state: IReportState, action: PayloadAction<IReportAdditionalChargesDto | undefined>) => {
                state.additionalChargesDetails = action.payload;
                state.status = 'loaded';
            })
            .addCase(getAdditionalChargesReportDetail.rejected, (state: IReportState, error) => {
                state.status = 'failed';
            })
            .addCase(downloadAdditionalChargesDetailAsExcel.pending, (state: IReportState) => {
                state.downloadStatus = 'loading';
            })
            .addCase(downloadAdditionalChargesDetailAsExcel.fulfilled, (state: IReportState) => {
                state.downloadStatus = 'loaded';
            })
            .addCase(downloadAdditionalChargesDetailAsExcel.rejected, (state: IReportState, error) => {
                state.downloadStatus = 'failed';
            })
            .addCase(getOrderAssessorialReportDetail.pending, (state: IReportState) => {
                state.status = 'loading';
            })
            .addCase(getOrderAssessorialReportDetail.fulfilled, (state: IReportState, action: PayloadAction<IReportOrderAssessorialDetailsDto | undefined>) => {
                state.orderAssessorialDetails = action.payload;
                state.status = 'loaded';
            })
            .addCase(getOrderAssessorialReportDetail.rejected, (state: IReportState, error) => {
                state.status = 'failed';
            })
            .addCase(downloadOrderAssessorialDetailAsExcel.pending, (state: IReportState) => {
                state.downloadStatus = 'loading';
            })
            .addCase(downloadOrderAssessorialDetailAsExcel.fulfilled, (state: IReportState) => {
                state.downloadStatus = 'loaded';
            })
            .addCase(downloadOrderAssessorialDetailAsExcel.rejected, (state: IReportState, error) => {
                state.downloadStatus = 'loading';
            })
            .addCase(getAdditionalChargesNames.pending, (state: IReportState) => {
                state.status = 'loading';
            })
            .addCase(getAdditionalChargesNames.fulfilled, (state: IReportState, action: PayloadAction<string[] | undefined>) => {
                state.status = 'loaded';
                state.chargeNames = action.payload;
            })
            .addCase(getAdditionalChargesNames.rejected, (state: IReportState, error) => {
                state.status = 'failed';
            })
            .addCase(getSpendReportDetail.pending, (state: IReportState) => {
                state.status = 'loading';
            })
            .addCase(getSpendReportDetail.fulfilled, (state: IReportState, action: PayloadAction<IReportSpendDetailsDto | undefined>) => {
                state.spendDetails = action.payload;
                state.status = 'loaded';
            })
            .addCase(getSpendReportDetail.rejected, (state: IReportState, error) => {
                state.status = 'failed';
            })
            .addCase(downloadSpendDetailAsExcel.pending, (state: IReportState) => {
                state.downloadStatus = 'loading';
            })
            .addCase(downloadSpendDetailAsExcel.fulfilled, (state: IReportState) => {
                state.downloadStatus = 'loaded';
            })
            .addCase(downloadSpendDetailAsExcel.rejected, (state: IReportState, error) => {
                state.downloadStatus = 'loading';
            })
            .addCase(getAccesoriesReportDetail.pending, (state: IReportState) => {
                state.status = 'loading';
            })
            .addCase(getAccesoriesReportDetail.fulfilled, (state: IReportState, action: PayloadAction<IReportAccesoriesDetailsDto | undefined>) => {
                state.accesoriesDetails = action.payload;
                state.status = 'loaded';
            })
            .addCase(getAccesoriesReportDetail.rejected, (state: IReportState, error) => {
                state.status = 'failed';
            })
            .addCase(downloadAccesoriesDetailAsExcel.pending, (state: IReportState) => {
                state.downloadStatus = 'loading';
            })
            .addCase(downloadAccesoriesDetailAsExcel.fulfilled, (state: IReportState) => {
                state.downloadStatus = 'loaded';
            })
            .addCase(downloadAccesoriesDetailAsExcel.rejected, (state: IReportState, error) => {
                state.downloadStatus = 'loading';
            })
            .addCase(getExpediteReportDetail.pending, (state: IReportState) => {
                state.status = 'loading';
            })
            .addCase(getExpediteReportDetail.fulfilled, (state: IReportState, action: PayloadAction<IReportExpediteDetailsDto | undefined>) => {
                state.expediteDetails = action.payload;
                state.status = 'loaded';
            })
            .addCase(getExpediteReportDetail.rejected, (state: IReportState, error) => {
                state.status = 'failed';
            })
            .addCase(downloadExpediteDetailAsExcel.pending, (state: IReportState) => {
                state.downloadStatus = 'loading';
            })
            .addCase(downloadExpediteDetailAsExcel.fulfilled, (state: IReportState) => {
                state.downloadStatus = 'loaded';
            })
            .addCase(downloadExpediteDetailAsExcel.rejected, (state: IReportState, error) => {
                state.downloadStatus = 'loading';
            })
            .addCase(getGLCodeReportDetail.pending, (state: IReportState) => {
                state.status = 'loading';
            })
            .addCase(getGLCodeReportDetail.fulfilled, (state: IReportState, action: PayloadAction<IReportGLCodeDetailsDto | undefined>) => {
                state.gLCodeDetails = action.payload;
                state.status = 'loaded';
            })
            .addCase(getGLCodeReportDetail.rejected, (state: IReportState, error) => {
                state.status = 'failed';
            })
            .addCase(downloadGLCodeDetailAsExcel.pending, (state: IReportState) => {
                state.downloadStatus = 'loading';
            })
            .addCase(downloadGLCodeDetailAsExcel.fulfilled, (state: IReportState) => {
                state.downloadStatus = 'loaded';
            })
            .addCase(downloadGLCodeDetailAsExcel.rejected, (state: IReportState, error) => {
                state.downloadStatus = 'loading';
            })
    }
});

export const ReportsAction = reportsStore.actions;

export default reportsStore.reducer;