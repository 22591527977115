import { IState } from "@common/state";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAmosMapItemDto, IAmosMapOrderDetailDto } from "contracts/amos";
import { getDockSchedulerMapOrders, getMapOrderDetail } from "dockScheduler/@components/map/@core/dockSchedulerMap";
import { LatLng } from "leaflet";
import { EventType, IMapLocations } from "dashboard/@core/common";
import { isValidLocation } from "dashboard/@core/mapUtils";

interface IDockSchedulerState {
  mapOrders: IState<IAmosMapItemDto[]>;
  locations: IMapLocations,

  orderDetailsLocations: IMapLocations,
  orderDetails: { [id: string]: IState<IAmosMapOrderDetailDto> },
  orderCoordinates: { [id: string]: LatLng[] };
  selectedOrderId?: string
}

const initialState: IDockSchedulerState = {
  orderDetails: {},
  orderDetailsLocations: {
    PICKUP: [],
    DROP: [],
    TRACKING: []
  },
  locations: {
    PICKUP: [],
    DROP: [],
    TRACKING: []
  },
  orderCoordinates: {},
  mapOrders: {
    status: 'notLoaded',
  },
}

export const dockSchedulerMapStore = createSlice({
  name: 'dockSchedulerMapStore',
  initialState,
  reducers: {
    clearStore: () => {
      return {
        mapOrders: initialState.mapOrders,
        locations: initialState.locations,
        orderDetails: initialState.orderDetails,
        orderDetailsLocations: initialState.orderDetailsLocations,
        orderCoordinates: initialState.orderCoordinates
      };
    },
    setOrderId: (state, { payload }: PayloadAction<string | undefined>) => {
      state.selectedOrderId = payload;
    },
    setOrderCoordinates: (state, { payload }: PayloadAction<{ orderGuid: string, coordinates: LatLng[] }>) => {
      state.orderCoordinates[payload.orderGuid] = payload.coordinates;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDockSchedulerMapOrders.pending, (state: IDockSchedulerState) => {
        state.mapOrders = { ...state.mapOrders, status: 'loading' };
      })
      .addCase(getDockSchedulerMapOrders.fulfilled, (state: IDockSchedulerState, action) => {
        state.mapOrders = { data: action.payload?.elements, status: 'loaded' };
        const locations: IMapLocations = {
          PICKUP: [],
          DROP: [],
          TRACKING: []
        };
        state.mapOrders.data?.forEach(order => {
          if (isValidLocation(order.firstEventLocation)) {
            locations.PICKUP.push({
              orderGuid: order.guid,
              eventGuid: order.firstEventLocation?.eventGuid,
              latitude: order.firstEventLocation?.latitude,
              longitude: order.firstEventLocation?.longitude,
            });
          }

          if (isValidLocation(order.lastEventLocation)) {
            locations.DROP.push({
              orderGuid: order.guid,
              eventGuid: order.lastEventLocation?.eventGuid,
              latitude: order.lastEventLocation?.latitude,
              longitude: order.lastEventLocation?.longitude,
            });
          }

          if (isValidLocation(order.lastStatusMessageLocation)) {
            locations.TRACKING.push({
              orderGuid: order.guid,
              eventGuid: order.lastStatusMessageLocation?.eventGuid,
              latitude: order.lastStatusMessageLocation?.latitude,
              longitude: order.lastStatusMessageLocation?.longitude,
              transportationMode: order.transportationModeStoredValue
            });
          }

        });
        state.locations = locations;
      })
      .addCase(getDockSchedulerMapOrders.rejected, (state: IDockSchedulerState) => {
        state.mapOrders = { ...state.mapOrders, status: 'failed' };
      })
      .addCase(getMapOrderDetail.pending, (state: IDockSchedulerState, action) => {
        state.orderDetails[action.meta.arg] = { ...state.orderDetails[action.meta.arg], status: 'loading' };
      })
      .addCase(getMapOrderDetail.fulfilled, (state: IDockSchedulerState, action) => {
        state.orderDetails[action.meta.arg] = { data: action.payload, status: 'loaded' };

        action.payload?.events.forEach(evt => {
          if (isValidLocation(evt.location)) {
            if (!state.locations[evt.eventType as EventType].find(t => t.eventGuid == evt.guid))
              state.orderDetailsLocations[evt.eventType as EventType].push({
                orderGuid: action.meta.arg,
                eventGuid: evt.guid,
                latitude: evt.location.latitude,
                longitude: evt.location.longitude,
              });
          }
        });

        if (isValidLocation(action.payload?.lastStatusMessage?.location)) {
          if (!state.locations['TRACKING'].find(t => t.eventGuid == action.payload?.lastStatusMessage?.eventGuid))
            state.orderDetailsLocations['TRACKING'].push({
              orderGuid: action.meta.arg,
              eventGuid: action.payload?.lastStatusMessage?.eventGuid,
              latitude: action.payload?.lastStatusMessage?.location.latitude,
              longitude: action.payload?.lastStatusMessage?.location.longitude,
              transportationMode: action.payload?.transportationModeStoredValue
            });
        }

      })
      .addCase(getMapOrderDetail.rejected, (state: IDockSchedulerState, action) => {
        state.orderDetails[action.meta.arg] = { ...state.orderDetails[action.meta.arg], status: 'failed' };
      });
  }
});

export const DockSchedulerMapAction = dockSchedulerMapStore.actions;

export default dockSchedulerMapStore.reducer;