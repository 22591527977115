import { useAppDispatch, useAppSelector } from "@core/hooks";
import { RootState } from "@core/store";
import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { setTitle } from "../store/appStore";
import { selectAccessInfo } from "@core/permissionsHelper";
import getMenuItems from "../MenuItems";

export default function LayoutTitle() {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const { title } = useAppSelector((state: RootState) => state.app);
  const accessInfo = useAppSelector((state) => selectAccessInfo(state))

  useEffect(() => {
    const foundItem = getMenuItems(accessInfo).find(t =>
      t.path === '/' && pathname === '/'
      || t.path !== '/' && pathname.trimStart().startsWith(t.path));

    if (foundItem !== title) {
      dispatch(setTitle({ title: foundItem?.title }))
    }
  }, [pathname])

  return (
    <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
      {typeof title === 'string' ? (
        <Typography variant="h6" noWrap>
          {title}
        </Typography>
      ) : (
        title
      )}
    </Box>
  )
}
