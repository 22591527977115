import { IState } from '@common/state';
import { getServerAddress } from '@core/appVariables';
import { fetchClient } from '@core/fetchClient';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IAmosMapItemsDto, IAmosMapOrderDetailDto, IAmosOrderRequestDto, IAmosTransportationMapIconTypeDto } from 'contracts/amos';
import { EventType, IMapLocations } from 'dashboard/@core/common';
import { isValidLocation } from 'dashboard/@core/mapUtils';

export interface IMapState {
  showMap: boolean;
  transportationMode: IState<IAmosTransportationMapIconTypeDto[]>;
  mapOrders: IState<IAmosMapItemsDto>;
  locations: IMapLocations,

  orderDetailsLocations: IMapLocations,
  orderDetails: { [id: string]: IState<IAmosMapOrderDetailDto> },
}

const initialState: IMapState = {
  showMap: true,
  orderDetails: {},
  orderDetailsLocations: {
    PICKUP: [],
    DROP: [],
    TRACKING: []
  },

  transportationMode: {
    status: 'notLoaded',
    data: []
  },
  locations: {
    PICKUP: [],
    DROP: [],
    TRACKING: []
  },
  mapOrders: {
    status: 'notLoaded',
  },
};


export const getMapOrderDetail = createAsyncThunk(
  'map/order/details',
  async (orderGuid: string) => {
    const response = await fetchClient().get<IAmosMapOrderDetailDto>(`${getServerAddress('/orders/api')}/map/order/${orderGuid}`);

    return response;
  }
);

export const getMapOrders = createAsyncThunk(
  'orders/map',
  async (request: IAmosOrderRequestDto) => {
    const response = await fetchClient().post<IAmosOrderRequestDto, IAmosMapItemsDto>(`${getServerAddress('/orders/api')}/map`, request);

    return response;
  }
);

export const getTransportationMode = createAsyncThunk(
  'transportation/mode',
  async () => {
    const response = await fetchClient().get<IAmosTransportationMapIconTypeDto[]>(`${getServerAddress('/orders/api')}/transportation/mode`);

    return response;
  }
);


export const mapStore = createSlice({
  name: 'map',
  initialState,
  reducers: {
    clearStore: () => {
      return {
        mapOrders: initialState.mapOrders,
        locations: initialState.locations,
        orderDetails: initialState.orderDetails,
        orderDetailsLocations: initialState.orderDetailsLocations,
        transportationMode: initialState.transportationMode,
        showMap: true,
      };
    },
    setShowMapState: (state) => {
      state.showMap = !state.showMap;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMapOrders.pending, (state: IMapState) => {
        state.mapOrders = { ...state.mapOrders, status: 'loading' };
      })
      .addCase(getMapOrders.fulfilled, (state: IMapState, action) => {

        state.mapOrders = { data: action.payload, status: 'loaded' };
        const locations: IMapLocations = {
          PICKUP: [],
          DROP: [],
          TRACKING: []
        };
        state.mapOrders.data?.elements?.forEach(order => {
          if (isValidLocation(order.firstEventLocation)) {
            locations.PICKUP.push({
              orderGuid: order.guid,
              eventGuid: order.firstEventLocation?.eventGuid,
              latitude: order.firstEventLocation?.latitude,
              longitude: order.firstEventLocation?.longitude,
            });
          }

          if (isValidLocation(order.lastEventLocation)) {
            locations.DROP.push({
              orderGuid: order.guid,
              eventGuid: order.lastEventLocation?.eventGuid,
              latitude: order.lastEventLocation?.latitude,
              longitude: order.lastEventLocation?.longitude,
            });
          }

          if (isValidLocation(order.lastStatusMessageLocation)) {
            locations.TRACKING.push({
              orderGuid: order.guid,
              eventGuid: order.lastStatusMessageLocation?.eventGuid,
              latitude: order.lastStatusMessageLocation?.latitude,
              longitude: order.lastStatusMessageLocation?.longitude,
              transportationMode: order.transportationModeStoredValue
            });
          }

        });
        state.locations = locations;
      })
      .addCase(getMapOrders.rejected, (state: IMapState) => {
        state.mapOrders = { ...state.mapOrders, status: 'failed' };
      })
      .addCase(getTransportationMode.pending, (state: IMapState) => {
        state.transportationMode = { ...state.transportationMode, status: 'loading' };
      })
      .addCase(getTransportationMode.fulfilled, (state: IMapState, action) => {
        state.transportationMode = { data: action.payload as IAmosTransportationMapIconTypeDto[], status: 'loaded' };
      })
      .addCase(getTransportationMode.rejected, (state: IMapState) => {
        state.transportationMode = { ...state.transportationMode, status: 'failed' };
      })
      .addCase(getMapOrderDetail.pending, (state: IMapState, action) => {
        state.orderDetails[action.meta.arg] = { ...state.orderDetails[action.meta.arg], status: 'loading' };
      })
      .addCase(getMapOrderDetail.fulfilled, (state: IMapState, action) => {
        state.orderDetails[action.meta.arg] = { data: action.payload, status: 'loaded' };

        action.payload?.events.forEach(evt => {
          if (isValidLocation(evt.location)) {
            if (!state.locations[evt.eventType as EventType].find(t => t.eventGuid == evt.guid))
              state.orderDetailsLocations[evt.eventType as EventType].push({
                orderGuid: action.meta.arg,
                eventGuid: evt.guid,
                latitude: evt.location.latitude,
                longitude: evt.location.longitude,
              });
          }
        });

        if (isValidLocation(action.payload?.lastStatusMessage?.location)) {
          if (!state.locations['TRACKING'].find(t => t.eventGuid == action.payload?.lastStatusMessage?.eventGuid))
            state.orderDetailsLocations['TRACKING'].push({
              orderGuid: action.meta.arg,
              eventGuid: action.payload?.lastStatusMessage?.eventGuid,
              latitude: action.payload?.lastStatusMessage?.location.latitude,
              longitude: action.payload?.lastStatusMessage?.location.longitude,
              transportationMode: action.payload?.transportationModeStoredValue
            });
        }

      })
      .addCase(getMapOrderDetail.rejected, (state: IMapState, action) => {
        state.orderDetails[action.meta.arg] = { ...state.orderDetails[action.meta.arg], status: 'failed' };
      });
  }
});

export const MapAction = mapStore.actions;

export default mapStore.reducer;