import { Box, SvgIcon, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MapTransportationIcon from "../../../app/MapTransportationIcon";
import { useAppSelector } from "@core/hooks";
import { TransportationIconTypeEnum } from "contracts/amos";

const useStyles = makeStyles((theme: Theme) => ({
    line: {
        display: 'flex',
        justifyContent: 'center',
        width: '95%',
        position: 'absolute',
        bottom: "12px",
        left: "34px",
        margin: "0 10px 0 10px"
    },
    arrivedLine: {
        position: "absolute",
        bottom: 0,
        left: 0,
        borderBottom: "4px solid #2e4053",
        borderBottomColor: theme.palette.grey[800]
    },
    emptyLine: {
        width: "100%",
        borderBottom: "4px solid",
        borderBottomColor: theme.palette.action.focus
    }
}));


interface LoadLineProps {
    showTracking: boolean,
    completed?: boolean
}

const LoadLine = (props: LoadLineProps) => {
    const classes = useStyles();
    const transportationIconType = useAppSelector((x) => x.orderDetail.order?.transportationIconType);

    return (
        <Box className={classes.line}>
            {props.showTracking && (
                <>
                    <Box className={classes.arrivedLine} width="50%"></Box>
                    <Box position='absolute' bottom={-4}>
                        <Box position='absolute' top={5} left={11}>
                            <MapTransportationIcon transportationIconType={transportationIconType || TransportationIconTypeEnum.Car} />
                        </Box>
                        <SvgIcon sx={{ width: "45px", height: "45px" }} viewBox="0 0 26 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.9998 0.166504C5.90484 0.166504 0.166504 5.90484 0.166504 12.9998C0.166504 22.6248 12.9998 36.8332 12.9998 36.8332C12.9998 36.8332 25.8332 22.6248 25.8332 12.9998C25.8332 5.90484 20.0948 0.166504 12.9998 0.166504Z" fill="#F7931E" fill-opacity="0.5" />
                        </SvgIcon>
                    </Box>
                    <Box className={classes.emptyLine}></Box>
                </>
            )}

            {!props.showTracking && !props.completed && (
                <Box className={classes.emptyLine}></Box>
            )}

            {!props.showTracking && props.completed && (
                <Box className={classes.arrivedLine} width="100%"></Box>
            )}
        </Box>
    )
}

export default LoadLine;