import { downloadFile } from "@common/nalFileManager";
import { getServerAddress } from "@core/appVariables";
import { fetchClient } from "@core/fetchClient";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IReportBaseFilterDto, IReportGLCodeDetailsDto } from "contracts/report";


export const getGLCodeReportDetail = createAsyncThunk(
    'reports/gLCode/details',
    async (request: IReportBaseFilterDto) => {
        const response = await fetchClient().post<IReportBaseFilterDto, IReportGLCodeDetailsDto>(`${getServerAddress('/analytics/api')}/glcode/details`, request);

        return response;
    }
)

export const downloadGLCodeDetailAsExcel = createAsyncThunk(
    'reports/gLCode/{branchGuid}/xml',
    async (request: IReportBaseFilterDto) => {
        const fileData = await fetchClient().stream(`${getServerAddress('/analytics/api')}/glcode/export`, "POST", request, 'application/json');

        downloadFile(fileData.name, fileData.contentType, new Uint8Array(await fileData.content.arrayBuffer()));
    }
);