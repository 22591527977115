import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VariantType } from 'notistack';

export type AppNotifyId = number;

export type AppNotifyDetail
  = {
    kind: VariantType,
    message: string | string[],
    messages?: string[],
  };

export type AppNotify
  = { id: AppNotifyId }
  & AppNotifyDetail;
export interface NotificationState {
  notifications: ReadonlyArray<AppNotify>;
  lastIndex: number;
}

const initialState: NotificationState = {
  notifications: [],
  lastIndex: 0,
};

type AddAppNotifyPayload = {
  id?: AppNotifyId
} & AppNotifyDetail;

export const notification = createSlice({
  name: 'notification',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    addAppNotification: (state, { payload }: PayloadAction<AddAppNotifyPayload>) => {
      state.lastIndex = state.lastIndex + 1;
      state.notifications = [...state.notifications, { id: payload.id || state.lastIndex + 1, ...payload }];
    },
    dismissAppNotification: (state, { payload }: PayloadAction<AppNotifyId>) => {
      state.notifications = state.notifications.filter(t => t.id !== payload);
    },
  }
});

export const { addAppNotification, dismissAppNotification } = notification.actions;

export default notification.reducer;
