import { fetchClient } from "@core/fetchClient";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IUser } from "auth/logic/domain/User";
import localStorageHelper from "@core/localstorageHelper";
import { decodeToken } from "react-jwt";
import { AuthHelper } from "@core/authHelper";
import { getServerAddress } from "@core/appVariables";
import { IAuthLoginRequestDto, IAuthLoginResponseDto } from "contracts/auth";

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const loginAsync = createAsyncThunk(
  'auth/login',
  async (user: IAuthLoginRequestDto) => {

    const response = await fetchClient().post<IAuthLoginRequestDto, IAuthLoginResponseDto>(`${getServerAddress('/identity/api')}/auth`, user);

    const accessToken = response?.accessToken || '';

    localStorageHelper.setAuthToken(accessToken);
    localStorageHelper.setRefreshToken(response?.refreshToken || '');

    return getUserFromToken(accessToken);
  }
);

export const getUserFromToken = (accessToken: string): IUser => {
  const decodedToken = decodeToken(accessToken) as any;
  return {
    subject: decodedToken[AuthHelper.claims.subject],
    email: decodedToken[AuthHelper.claims.email],
    name: decodedToken[AuthHelper.claims.name],
    initials: decodedToken[AuthHelper.claims.initials],
    role: decodedToken[AuthHelper.claims.role],
    branchId: decodedToken[AuthHelper.claims.branchId],
    branchName: decodedToken[AuthHelper.claims.branchName],
    availableDocumentTypeGuids: decodedToken[AuthHelper.claims.availableDocumentTypeGuids],
    permissions: decodedToken[AuthHelper.claims.permissions],
    reportTypes: decodedToken[AuthHelper.claims.reportTypes]
  } as IUser;
}


