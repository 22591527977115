import { fetchClient } from "@core/fetchClient";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { addAppNotification } from "appNotification";
import { getServerAddress } from "@core/appVariables";
import { IAuthResetPasswordRequestDto } from "contracts/auth";

export const forgotPassword = createAsyncThunk(
    'auth/reset/link',
    async (dto: IAuthResetPasswordRequestDto, trunk) => {
      await fetchClient().post<IAuthResetPasswordRequestDto, void>(`${getServerAddress('/identity/api')}/auth/reset/link`, dto)
  
      trunk.dispatch(addAppNotification({
        kind: 'success',
        message: 'Check your email!',
      }));
    }
  )