import { Grid, Table, TableBody, TableRow, TableCell, Box, Typography } from "@mui/material";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PortraitIcon from '@mui/icons-material/Portrait';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { CurrencyType, IAmosCarrierDto, IAmosDocumentsDto, IAmosPaymentDto, PaymentTerm } from "contracts/amos";
import DownloadIcon from '@mui/icons-material/Download';
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@core/hooks";
import { downloadDocument } from "orderDetail/@store/orderDetailStore";
import NalCard from "@common/@components/NalCard";
import NalInfoCell from "@common/@components/NalInfoCell";
import { selectAccessInfo, viewRateAccess } from "@core/permissionsHelper";

interface IOrderInfo {
    carrier: IAmosCarrierDto | undefined,
    payments: IAmosPaymentDto | undefined,
    documents: IAmosDocumentsDto | undefined,
}

const OrderInfoBlock = (props: IOrderInfo) => {
    const dispatch = useAppDispatch();
    const accessInfo = useAppSelector((state) => selectAccessInfo(state));
    const { id } = useParams();

    const handleDownloadDocument = (documentGuid: string) => {
        dispatch(downloadDocument({ orderGuid: id!, documentGuid: documentGuid }));
    }

    return (
        <Grid container spacing={10}>
            {viewRateAccess(accessInfo) && (
                <Grid item xs={12} sm={4}>
                    <NalCard
                        title="PAYMENTS"
                        icon={() => <AttachMoneyIcon color="action" />}
                        content={
                            <Table>
                                <TableBody>
                                    {props.payments && (
                                        <>
                                            <TableRow>
                                                <TableCell>
                                                    <NalInfoCell title="Payment Terms:" value={PaymentTerm[props.payments.paymentTerm as keyof typeof PaymentTerm]} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <NalInfoCell title="Order:" value={""} />
                                                    <NalInfoCell title="Main:" value={`${CurrencyType[props.payments.currency as keyof typeof CurrencyType]} ${props.payments.mainChargesTotal ? props.payments.mainChargesTotal : "0.00"}`} />
                                                    <NalInfoCell title="Fuel:" value={`${CurrencyType[props.payments.currency as keyof typeof CurrencyType]} ${props.payments.fuelChargesTotal ? props.payments.fuelChargesTotal : "0.00"}`} />
                                                    <NalInfoCell title="Other:" value={`${CurrencyType[props.payments.currency as keyof typeof CurrencyType]} ${props.payments.discountChargesTotal ? props.payments.discountChargesTotal : "0.00"}`} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <NalInfoCell title="Estimated Total:" value={`${CurrencyType[props.payments.currency as keyof typeof CurrencyType]} ${props.payments.total ? props.payments.total : "0.00"}`} textAlignEnd={true} />
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    )}
                                </TableBody>
                            </Table>
                        }
                    />
                </Grid>
            )}
            <Grid item xs={12} sm={4}>
                <NalCard
                    title="CARRIER"
                    icon={() => <PortraitIcon color="action" />}
                    content={
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <NalInfoCell title="Carrier Name:" value={props.carrier?.name ?? ""} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    }
                />
            </Grid>
            {props.documents?.elements && props.documents.elements.length > 0 && (
                <Grid item xs={12} sm={4}>
                    <NalCard
                        title="DOCUMENTS"
                        icon={() => <AssignmentIcon color="action" />}
                        content={
                            <Box display="flex" flexDirection="column" gap={1}>
                                {props.documents?.elements.map((document, index) => {
                                    return (
                                        <Box key={index} display="flex" alignItems="center" justifyContent="space-between">
                                            <Box>
                                                <Typography variant="subtitle2">{document.documentTypeDisplayedValue}</Typography>
                                                <Typography variant="body2">{document.fileName}</Typography>
                                            </Box>
                                            <DownloadIcon sx={{ cursor: "pointer" }} onClick={() => handleDownloadDocument(document.guid)} />
                                        </Box>
                                    );
                                })}
                            </Box>
                        }
                    />
                </Grid>
            )}
        </Grid>
    );
}

export default OrderInfoBlock;