import { Box, Typography } from "@mui/material";

interface INalInfoCell {
    title: string;
    value: string;
    valueColor?: string;
    textAlignEnd?: boolean
}

const NalInfoCell = (props: INalInfoCell) => {
    return (
        <Box display="flex" alignItems="center" justifyContent={props.textAlignEnd ? "end" : "start"} gap={1} >
            <Typography variant="subtitle2">{props.title}</Typography>
            <Typography variant="subtitle1" color={props.valueColor ?? ""} >{props.value}</Typography>
        </Box>
    );
}

export default NalInfoCell;