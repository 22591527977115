export interface INalFile {
    name: string;
    contentType: string;
    content: Blob;
}

export const downloadFile = (name: string, contentType: string, content: Uint8Array) => {
    const file = new File([content], name, { type: contentType });
    const exportUrl = URL.createObjectURL(file);

    const a = document.createElement("a");
    document.body.appendChild(a);
    a.href = exportUrl;
    a.download = name;
    a.target = "_blank";
    a.click();

    URL.revokeObjectURL(exportUrl);
};