import OrderDetailTab from "./@components/OrderDetailTab";
import OrderInfoBlock from "./@components/OrderInfoBlock";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@core/hooks";
import { Grid, Paper, Box, Typography, Chip, Theme } from "@mui/material";
import { getOrderDetails, getOrderDocuments, getOrderStatusMessages } from "orderDetail/@store/orderDetailStore";
import { useParams } from "react-router-dom";
import { StatusColorMap, StatusColorMapType } from "dashboard/@core/statusColorMap";
import { useFullWidth } from "app/styles/styleExtension";
import { makeStyles } from "@mui/styles";
import { getOrderReference } from "./@store/orderReferencesStore";
import { RoleHelper } from "@core/roleHelper";
import LoadingBox from "@common/@components/LoadingBox";
import EventBlockControl from "./@components/events/EventBlockControl";
import { getBranchesList } from "@common/Filters/@store/filterStore";

const useStyles = makeStyles(() => ({
    orderDetailWidth: {
        width: "87vw"
    },
    eventBlock: {
        maxWidth: "100%",
        overflowX: "auto",
        overflowY: "hidden"
    }
}));

const OrderDetail = () => {
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const fullWidthStyles = useFullWidth();
    const classes = useStyles();

    const branches = useAppSelector((state) => state.filter.branchList);
    const status = useAppSelector((state) => state.orderDetail.status);
    const { user } = useAppSelector((state) => state.auth);
    const { order, documents } = useAppSelector((state) => state.orderDetail);

    useEffect(() => {
        const branchGuid = user?.branchId || '';

        if (branchGuid) {
            dispatch(getBranchesList(branchGuid));
        }
    }, [user])

    useEffect(() => {
        if (id && user) {
            dispatch(getOrderDetails(id));
            dispatch(getOrderReference(id));
            dispatch(getOrderStatusMessages(id));

            const availableDocuments = user.availableDocumentTypeGuids?.split(',').filter(item => item !== '');
            if ((availableDocuments && availableDocuments.length > 0) || user.role.includes(RoleHelper.roles.superAdmin)) {
                dispatch(getOrderDocuments({ orderGuid: id, availableDocumentTypeGuids: availableDocuments }));
            }
        }
    }, [id]);

    if (status === 'notLoaded' || status === 'loading') {
        return (
            <Box className={classes.orderDetailWidth}>
                <LoadingBox />
            </Box>
        )
    }

    return (
        <Grid container direction="column" gap={5} className={classes.orderDetailWidth}>
            <Grid item>
                <Paper
                    elevation={2}
                    sx={{ padding: 2 }}>
                    <Grid container gap={6}>
                        <Box display="flex" alignItems="center" gap={3}>
                            <Typography variant="h5">Order: {order?.primaryReferenceValue}</Typography>
                            {order?.status && (
                                <Chip label={order.status}
                                    style={{
                                        color: StatusColorMap[order.status as StatusColorMapType].color,
                                        borderColor: (StatusColorMap[order.status as StatusColorMapType].color)
                                    }}
                                    variant="outlined"
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid container gap={6} mt={2} alignItems="center" >
                        {order?.orderTypeDisplayedValue && <Typography variant="body2">Order Type: {order?.orderTypeDisplayedValue}</Typography>}
                        <Typography variant="body2">Total Trip Distance: {(order?.totalDistance && order?.totalDistance > 0) ? (order?.totalDistance + " " + order?.distanceUOM) : ""}</Typography>
                        {order?.carrier?.proNumber && (
                            <Box display="flex" alignItems="center" gap={1}>
                                <Typography variant="body2">Pickup Number:</Typography>
                                <Typography variant="subtitle1">{order.carrier.proNumber}</Typography>
                            </Box>
                        )}
                        <Box display="flex" alignItems="center" gap={1}>
                            <Typography variant="body2">Customer:</Typography>
                            <Typography variant="subtitle1">{branches?.find(x => x.storedValue === order?.branchGuid)?.displayedValue}</Typography>
                        </Box>
                    </Grid>
                </Paper>
            </Grid>
            <Box className={classes.eventBlock}>
                <EventBlockControl events={order?.events} items={order?.items} />
            </Box>
            <Box className={fullWidthStyles.root}>
                <OrderInfoBlock payments={order?.payment} carrier={order?.carrier} documents={documents} />
            </Box>
            <Box className={fullWidthStyles.root}>
                <OrderDetailTab />
            </Box>
        </Grid>
    );
}

export default OrderDetail;