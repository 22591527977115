import { Theme } from "@mui/material/styles";
import { useTheme } from "@mui/styles";
import { ChartColor } from "app/theme";
import chroma from "chroma-js";

const useAppTheme = () => {
    return useTheme() as Theme;
}

export default useAppTheme;

export enum ColorPaletteType {
    Default = 'default',
    PrimaryOnly = 'primaryOnly',
}

export const useColorPalette = (numColors: number, palette: ColorPaletteType = ColorPaletteType.Default) => {
    const colors = {
        [ColorPaletteType.Default]: [
            ChartColor.blue,
            ChartColor.teal,
            ChartColor.cyan,
            ChartColor.green,
            ChartColor.lightGreen,
            ChartColor.lime,
            ChartColor.orange,
            ChartColor.coral,
            ChartColor.red,
            ChartColor.pink,
            ChartColor.mustard,
            ChartColor.yellow,
            ChartColor.lightYellow,
            ChartColor.darkYellow,
        ],
        [ColorPaletteType.PrimaryOnly]: [
            ChartColor.blue,
            ChartColor.cyan,
            ChartColor.green,
            ChartColor.orange,
            ChartColor.red,
        ],
    };

    return chroma.scale(colors[palette]).mode('lch').colors(numColors);
}
