import { StateKind } from "@common/state";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AmosOrderSearchCriteriaPropertyEnum, IAmosOrderResultDto, IAmosOrderSortDto } from "contracts/amos";
import { SortOrderEnum } from "contracts/common";
import { getOrdersAsync } from "dockScheduler/@components/grid/@core/dockSchedulerOrder";

interface IDockSchedulerOrderState {
    status: StateKind;
    orders: IAmosOrderResultDto;
    toggleGrid: boolean;
    orderFields: IAmosOrderSortDto[]
}

const initialState: IDockSchedulerOrderState = {
    status: "notLoaded",
    orders: {
        results: [],
        totalCount: 0
    },
    toggleGrid: true,
    orderFields: [],
}

export const dockSchedulerOrderStore = createSlice({
    name: 'dockSchedulerOrderStore',
    initialState,
    reducers: {
        toggleGridAction: (state) => {
            state.toggleGrid = !state.toggleGrid;
        },
        setOrderFields: (state, action: PayloadAction<IAmosOrderSortDto[]>) => {
            action.payload.forEach(newField => {
                const index = state.orderFields.findIndex(
                    field => field.propertyName === field.propertyName
                );

                if (index !== -1) {
                    state.orderFields[index] = newField;
                } else {
                    state.orderFields.push(newField);
                }
            });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOrdersAsync.pending, (state: IDockSchedulerOrderState) => {
                state.status = 'loading';
            })
            .addCase(getOrdersAsync.fulfilled, (state: IDockSchedulerOrderState, action: PayloadAction<IAmosOrderResultDto>) => {
                state.status = 'loaded';
                state.orders = action.payload;
            })
            .addCase(getOrdersAsync.rejected, (state: IDockSchedulerOrderState, error) => {
                state.status = 'failed';
            })
    }
})

export const DockSchedulerOrderAction = dockSchedulerOrderStore.actions;

export default dockSchedulerOrderStore.reducer;