import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete, Checkbox, IconButton, Menu, MenuItem, TableCell, TableRow, TextField, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@core/hooks";
import { deleteReference, updateReference } from "orderDetail/@store/orderReferencesStore";
import { useEffect, useState } from "react";
import { useFullWidth } from "app/styles/styleExtension";
import NalDeleteConfirmModel from '@common/@components/NalDeleteConfirmModal';

interface IOrderDetailReferencesRowProps {
    guid: string;
}

const OrderDetailReferencesRow = (props: IOrderDetailReferencesRowProps) => {
    const dispatch = useAppDispatch();
    const widthClasses = useFullWidth();

    const reference = useAppSelector((state) => state.orderReferences.references?.find(x => x.guid === props.guid)!);
    const allowedValues = useAppSelector((state) => state.orderReferences.referencesTypes?.elements.find(x => x.name === reference.name)?.allowedValues);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [value, setValue] = useState<string>(reference.value || '')
    const [error, setError] = useState<string | null>(null)

    const [showUpdate, setShowUpdate] = useState<boolean>(false);
    const [showDeleteModal, setShowDelete] = useState<boolean>(false);

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleDeleteReference = (confirmed: boolean) => {
        setShowDelete(false);
        setShowUpdate(false);

        if (confirmed) {
            dispatch(deleteReference(reference.guid));
        }
    }

    const handleUpdateReference = () => {
        if (!value) {
            setError("Value is required");
            return;
        }

        dispatch(updateReference({
            guid: reference.guid,
            value: value
        }));

        reset();
    }

    const closeUpdate = () => {
        reset();
        setValue(reference.value || '');
    }

    const reset = () => {
        setShowUpdate(false);
        setShowDelete(false);
        setError(null);
        setAnchorEl(null)
    }

    useEffect(() => {
        setValue(reference.value || '')
    }, [reference.value])

    return (
        <>
            <TableRow>
                <TableCell>{reference.name}</TableCell>
                <TableCell>
                    {!showUpdate && value || (
                        !(allowedValues && allowedValues?.length > 0) && (
                            <TextField
                                size="small"
                                value={value}
                                className={widthClasses.half}
                                onChange={(e) => setValue(e.target.value)}
                                error={!!error}
                                helperText={error}
                            />
                        ) || (
                            <Autocomplete
                                value={value}
                                options={allowedValues!}
                                className={widthClasses.half}
                                onChange={(_, newValue) => setValue(newValue || '')}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Allowed Values"
                                        size="small"
                                        fullWidth
                                        error={!!error}
                                        helperText={error}
                                    />
                                )}
                            />
                        )
                    )}
                </TableCell>
                <TableCell padding="checkbox">
                    <Checkbox
                        disableRipple
                        color="success"
                        sx={{ cursor: 'default' }}
                        checked={reference.primary}
                    />
                </TableCell>
                <TableCell align="right">
                    {reference.updatable && (
                        <>
                            {!showUpdate ? (
                                <>
                                    <IconButton
                                        aria-label="more"
                                        onClick={handleMenuClick}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={() => setAnchorEl(null)}
                                    >
                                        <MenuItem onClick={() => setShowUpdate(true)}>
                                            <EditOutlinedIcon color="action" />
                                            <Typography variant="body1" ml={1}>Edit</Typography>
                                        </MenuItem>
                                        <MenuItem onClick={() => setShowDelete(true)}>
                                            <DeleteOutlinedIcon color="action" />
                                            <Typography variant="body1" ml={1}>Delete</Typography>
                                        </MenuItem>
                                    </Menu>
                                </>
                            ) : (
                                <>
                                    <IconButton onClick={handleUpdateReference}>
                                        <CheckIcon color="success" />
                                    </IconButton>
                                    <IconButton onClick={() => closeUpdate()}>
                                        <CloseIcon color="error" />
                                    </IconButton>
                                </>
                            )}
                        </>
                    )}
                </TableCell>
            </TableRow>
            <NalDeleteConfirmModel 
                showModal={showDeleteModal}
                onSubmit={(confirmed) => handleDeleteReference(confirmed)} 
                setHideModal={() => reset()}
                title='Delete Reference'
                description='You really want to delete this reference?'
            />
        </>
    )
}

export default OrderDetailReferencesRow;