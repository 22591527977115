import { TransportationIconTypeEnum } from "contracts/amos";
import './styles//nal-icons.css';


const iconMap: Record<TransportationIconTypeEnum, JSX.Element> = {
    [TransportationIconTypeEnum.Car]: <i className="icon icon-trak_outlined"></i>,
    [TransportationIconTypeEnum.Pallet]: <i className="icon icon-pallet"></i>,
    [TransportationIconTypeEnum.Train]: <i className="icon icon-train"></i>,
    [TransportationIconTypeEnum.Building]: <i className="icon icon-warehouse_outlined"></i>,
    [TransportationIconTypeEnum.Money]: <i className="icon icon-money_outlined"></i>,
    [TransportationIconTypeEnum.Plane]: <i className="icon icon-plane_outlined"></i>,
    [TransportationIconTypeEnum.Fast]: <i className="icon icon-expedite"></i>,
    [TransportationIconTypeEnum.Parcel]: <i className="icon icon-parcels"></i>,
    [TransportationIconTypeEnum.LargeShipment]: <i className="icon icon-volume_outlined"></i>,
    [TransportationIconTypeEnum.Container]: <i className="icon icon-container"></i>,
    [TransportationIconTypeEnum.HalfContainer]: <i className="icon icon-container_half"></i>,
    [TransportationIconTypeEnum.HalfTruck]: <i className="icon icon-half_trak"></i>,
};

interface MapTransportationIconProps {
    transportationIconType: TransportationIconTypeEnum;
}

const MapTransportationIcon = (props: MapTransportationIconProps) => iconMap[props.transportationIconType];
export default MapTransportationIcon;