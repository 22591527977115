import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DimensionsUOM, FreightClass, PackageType, WeightUOM } from "contracts/amos";

interface ICreateOrderItemCore {
    selected: boolean;
    show: boolean;
    systemId: string;
    eventIndex?: number;
}

export interface ICreateOrderItem extends ICreateOrderItemCore {
    itemId: string;
    description?: string;
    weight: number;
    weightUOM: WeightUOM;
    quantity?: number;
    packageType: PackageType;
    freightClass: FreightClass;
    nmfc?: string;
    dimensionsL?: number;
    dimensionsW?: number;
    dimensionsH?: number;
    dimensionsUOM: DimensionsUOM;
}

interface IOrderItemValidation {
    systemId: string;
    value: boolean;
}

interface ICreateOrderItemsState {
    items: ICreateOrderItem[];
    validations: IOrderItemValidation[];
}

const initialState: ICreateOrderItemsState = {
    items: [
        {
            itemId: '',
            show: true,
            selected: false,
            systemId: "0",
            weight: 0,
            weightUOM: WeightUOM.LB,
            freightClass: FreightClass.NoFreightClass,
            packageType: PackageType.PALLET,
            dimensionsUOM: DimensionsUOM.INCH,
        }
    ],
    validations: [
        {
            systemId: "0",
            value: true,
        }
    ]
}

export const createOrderItemsStore = createSlice({
    name: 'CreateOrderItemsStore',
    initialState,
    reducers: {
        updateItem: (state, action: PayloadAction<{ value: ICreateOrderItem }>) => {
            const items = [...state.items];
            const validations = [...state.validations];
            const index = items.findIndex(x => x.systemId === action.payload.value.systemId);

            if (index !== -1) {
                items[index] = action.payload.value;
            } else {
                items.push(action.payload.value);
                validations.push({
                    systemId: action.payload.value.systemId,
                    value: true,
                })
            }

            state.validations = validations;
            state.items = items;
        },
        deleteItem: (state, action: PayloadAction<{ systemId: string }>) => {
            const items = state.items.filter(x => x.systemId !== action.payload.systemId);
            const validations = state.validations.filter(x => x.systemId !== action.payload.systemId)

            state.items = items;
            state.validations = validations;
        },
        updateSelected: (state, action: PayloadAction<{ ids: string[], eventIndex: number }>) => {
            const updatedItems = state.items.map(item => {
                if (item.eventIndex === action.payload.eventIndex || !item.eventIndex) {
                    const isSelected = action.payload.ids.includes(item.systemId);
                    const updateIndex = isSelected ? action.payload.eventIndex : undefined;

                    return {
                        ...item,
                        selected: isSelected,
                        eventIndex: updateIndex,
                    };
                }

                return item;
            });

            state.items = updatedItems;
        },
        updateValidation: (state, action: PayloadAction<{ value: boolean, systemId: string }>) => {
            const index = state.validations.findIndex(x => x.systemId === action.payload.systemId);

            if (index !== -1) {
                const updatedValidations = [...state.validations];
                updatedValidations[index].value = action.payload.value;

                state.validations = updatedValidations;
            }
        },
        updateShow: (state, action: PayloadAction<ICreateOrderItemCore[]>) => {
            const updatedItems = state.items.map(item => {
                const updatedItem = action.payload.find(updated => updated.systemId === item.systemId);
                return updatedItem ? { ...item, show: updatedItem.show } : item;
            });

            state.items = updatedItems;
        },
        clearStore: () => {
            return {
                ...initialState,
                items: initialState.items.map(item => ({ ...item })),
                validations: initialState.validations.map(val => ({ ...val })),
            };
        },
    }
})

export const CreateOrderItemsStoreAction = createOrderItemsStore.actions;
export default createOrderItemsStore.reducer;