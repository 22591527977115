import { useAppDispatch, useAppSelector } from "@core/hooks";
import { Autocomplete, IconButton, TableCell, TableRow, TextField } from "@mui/material";
import { useFullWidth } from "app/styles/styleExtension";
import { OrderReferencesActions, createReference } from "orderDetail/@store/orderReferencesStore";
import { useState } from "react";
import { useParams } from "react-router-dom";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

interface IReferenceCreate {
    typeGuid: string,
    value: string,
}

const OrderDetailCreateReference = () => {
    const dispatch = useAppDispatch();
    const widthClasses = useFullWidth();

    const { id } = useParams();
    const referencesTypes = useAppSelector((state) => state.orderReferences.referencesTypes?.elements);
    const showCreate = useAppSelector((state) => state.orderReferences.showCreate);

    const [form, setForm] = useState<IReferenceCreate>({
        value: '',
        typeGuid: ''
    });
    const [errors, setErrors] = useState<Partial<IReferenceCreate> | null>()

    const handleCreateReference = () => {
        const newErrors: Partial<IReferenceCreate> = {};

        if (!form.typeGuid) {
            newErrors.typeGuid = "Type is required";
        }

        if (!form.value) {
            newErrors.value = "Value is required";
        }

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            dispatch(createReference({
                orderGuid: id!,
                referenceTypeGuid: form.typeGuid,
                value: form.value
            }));
            handleHideCreateReferences();
        }
    }

    const handleHideCreateReferences = () => {
        dispatch(OrderReferencesActions.showCreateReference());
        setErrors(null);
        setForm({
            typeGuid: '',
            value: ''
        });
    }

    return (
        <>
            {showCreate && (
                <TableRow>
                    <TableCell>
                        <Autocomplete
                            className={widthClasses.half}
                            options={referencesTypes || []}
                            getOptionLabel={(option) => option.name}
                            value={referencesTypes?.find((element) => element.guid === form.typeGuid) || null}
                            onChange={(_, newValue) => {
                                setForm((prevForm) => ({
                                    ...prevForm,
                                    typeGuid: newValue?.guid || '',
                                    typeName: newValue?.name || 'Select Type',
                                    value: '',
                                }));
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Reference Type"
                                    size="small"
                                    fullWidth
                                    error={!!errors?.typeGuid}
                                    helperText={errors?.typeGuid}
                                />
                            )}
                        />
                    </TableCell>
                    <TableCell>
                        {referencesTypes?.find((element) => element.guid === form.typeGuid)?.allowedValues?.length! > 0 ? (
                            <Autocomplete
                                className={widthClasses.half}
                                options={referencesTypes?.find((element) => element.guid === form.typeGuid)?.allowedValues || []}
                                value={form.value || null}
                                onChange={(_, newValue) => setForm({ ...form, value: newValue || '' })}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Allowed Values"
                                        size="small"
                                        fullWidth
                                        error={!!errors?.value}
                                        helperText={errors?.value}
                                    />
                                )}
                            />
                        ) : (
                            <TextField
                                label="Value"
                                size="small"
                                className={widthClasses.half}
                                value={form?.value || ''}
                                onChange={(e) => setForm({ ...form, value: e.target.value || '' })}
                                error={!!errors?.value}
                                helperText={errors?.value}
                            />
                        )}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right">
                        <>
                            <IconButton onClick={handleCreateReference}>
                                <CheckIcon color="success" />
                            </IconButton>
                            <IconButton onClick={handleHideCreateReferences}>
                                <CloseIcon color="error" />
                            </IconButton>
                        </>
                    </TableCell>
                </TableRow>
            )}
        </>
    )
}

export default OrderDetailCreateReference;