import { Box, Grid, SvgIcon, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { EventStatusEnum, EventType, IAmosEventDto, IAmosItemDto } from "contracts/amos";
import EventDetailCard from "./EventDetailCard";
import LoadLine from "./LoadLine";
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
    pickupColor: {
        backgroundColor: theme.palette.success.light,
        color: theme.palette.common.white
    },
    dropColor: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white
    },
    eventTitle: {
        color: theme.palette.grey[600]
    },
    eventContainer: {
        padding: "2px 10px",
        borderRadius: "50%"
    }
}));

interface EventBlockControlProps {
    events: IAmosEventDto[] | undefined;
    items: IAmosItemDto[] | undefined;
}

const EventBlockControl = (props: EventBlockControlProps) => {
    const classes = useStyles();

    const getItemIdByGuid = (guid: string) => {
        const item = props.items?.find((item) => item.guid === guid);

        return item?.itemId || null;
    };

    const getEventColor = (eventType: string) => {
        return eventType === EventType.PICKUP.toUpperCase() ? `${classes.pickupColor}` : classes.dropColor;
    };

    const getEventText = (eventType: string) => {
        return eventType === EventType.PICKUP.toUpperCase() ? `Pickup` : "Drop";
    };

    const shouldShowTracking = (index: number) => {
        return props.events![index].status === EventStatusEnum.COMPLETED &&
               (!props.events![index + 1] || props.events![index + 1].status === EventStatusEnum.PENDING);
    };

    return (
        <>
            <Typography variant="subtitle1" mb={2}>Events</Typography>
            <Grid container display='flex' flexDirection='column' width='max-content'>
                <Grid item display="flex" gap={5}>
                    {props.events?.map((event, index) => {
                        const showDashedLine = (index != props.events!.length - 1);

                        return (
                            <Box key={index}>
                                <Box display="flex" alignItems="start" mb={2} position="relative">
                                    <Box className={clsx(getEventColor(event.type ?? ""), classes.eventContainer)}>
                                        <Typography variant="body1">{event.index}</Typography>
                                    </Box>

                                    {showDashedLine && (
                                        <LoadLine showTracking={shouldShowTracking(index)} completed={event.status === EventStatusEnum.COMPLETED} />
                                    )}
                                </Box>

                                <Typography variant="subtitle2" className={classes.eventTitle} mb={1}>{getEventText(event.type ?? "")}</Typography>
                                <EventDetailCard event={event} itemsIds={event.itemGuids?.map((guid) => getItemIdByGuid(guid)) || []} />
                            </Box>
                        );
                    })}
                </Grid>
            </Grid>
        </>
    )
}

export default EventBlockControl;