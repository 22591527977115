import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { ReportTypeEnum } from "contracts/amos";
import { RoleHelper } from "./roleHelper";

export class PermissionHelper {

    public static readonly permissions = {
        createOrder: 'CreateOrder',
        viewRate: 'ViewRate',
        multiEvents: 'MultiEvents'
    }
}

export interface IUserAccessInfo {
    permissions: string | undefined;
    reportTypes: string | undefined;
    role: string | undefined;
}

export const selectAccessInfo = createSelector(
    [
        (state: RootState) => state.auth.user?.permissions,
        (state: RootState) => state.auth.user?.reportTypes,
        (state: RootState) => state.auth.user?.role,
    ],

    (permissions, reportTypes, role): IUserAccessInfo => {
        return { permissions, reportTypes, role }
    }
);

const isSuperAdmin = (accessInfo: IUserAccessInfo): boolean => {
    return accessInfo.role?.includes(RoleHelper.roles.superAdmin) || false;
}

export const isAdmin = (accessInfo: IUserAccessInfo): boolean => {
    return accessInfo.role?.includes(RoleHelper.roles.superAdmin) || accessInfo.role?.includes(RoleHelper.roles.admin) || false;
}

export const createOrderAccess = (accessInfo: IUserAccessInfo): boolean => {
    return (accessInfo.permissions?.includes(PermissionHelper.permissions.createOrder) || isSuperAdmin(accessInfo)) || false;
} 

export const viewRateAccess = (accessInfo: IUserAccessInfo): boolean => {
    return (accessInfo.permissions?.includes(PermissionHelper.permissions.viewRate) || isSuperAdmin(accessInfo)) || false;
}

export const multiEventsAccess = (accessInfo: IUserAccessInfo): boolean => {
    return (accessInfo.permissions?.includes(PermissionHelper.permissions.multiEvents) || isSuperAdmin(accessInfo)) || false;
}

export const anyReportsAccess = (accessInfo: IUserAccessInfo): boolean => {
    const hasSFSReportType = accessInfo.reportTypes?.includes(ReportTypeEnum.SFS.toString());
    const hasASSReportType = accessInfo.reportTypes?.includes(ReportTypeEnum.AdditionalCharges.toString());

    if ((hasSFSReportType || hasASSReportType) && accessInfo.reportTypes && accessInfo.reportTypes.length === 1) {
        return viewRateAccess(accessInfo);
    }

    return (accessInfo.reportTypes && accessInfo.reportTypes?.length > 0) || isSuperAdmin(accessInfo) || false;
}

export const reportAccess = (accessInfo: IUserAccessInfo, type: ReportTypeEnum): boolean => {
    if (type === ReportTypeEnum.SFS || type === ReportTypeEnum.AdditionalCharges || type === ReportTypeEnum.OrderDetailsAssessorial) {
        return (accessInfo.reportTypes?.includes(type.toString()) && viewRateAccess(accessInfo)) || isSuperAdmin(accessInfo) || false;
    }

    return accessInfo.reportTypes?.includes(type.toString()) || isSuperAdmin(accessInfo) || false;
}