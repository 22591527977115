import NALNavLink from "@common/NALNavLink";
import { useAppDispatch } from "@core/hooks";
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { logoutHandler } from "auth/store/logoutHandler";

interface Props {
  open?: boolean;
  title?: string,
  path: string;
  icon: () => JSX.Element;
}

export default function ListItemLink(props: Props) {
  const dispatch = useAppDispatch();
  
  const logoutClick = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => { 
    evt.preventDefault();

    logoutHandler(dispatch);
  };

  return (
    <ListItem disablePadding sx={{ display: 'block' }}>
      {
        props.path ?
          <ListItemButton
            LinkComponent={NALNavLink}
            href={props.path}
            sx={{
              minHeight: 48,
              justifyContent: props.open ? 'initial' : 'center',
              px: 2.5,
            }}

          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: props.open ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              {props.icon()}
            </ListItemIcon>
            <ListItemText primary={props.title} sx={{ opacity: props.open ? 1 : 0 }} />
          </ListItemButton>
          :
          <ListItemButton
            onClick={evt => logoutClick(evt)}
            sx={{
              minHeight: 48,
              justifyContent: props.open ? 'initial' : 'center',
              px: 2.5,
            }}

          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: props.open ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              {props.icon()}
            </ListItemIcon>
            <ListItemText primary={props.title} sx={{ opacity: props.open ? 1 : 0 }} />
          </ListItemButton>
      }


    </ListItem>
  );
}