import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IMapDetailsState {
  selectedOrderId?: string
}

const initialState: IMapDetailsState = {
};

export const mapDetailsStore = createSlice({
  name: 'mapDetails',
  initialState,
  reducers: {
    setOrderId: (state, { payload }: PayloadAction<string>) => {
      state.selectedOrderId = payload;
    }
  },
});

export const MapDetailsAction = mapDetailsStore.actions;

export default mapDetailsStore.reducer;