import { Avatar, Box, List, ListItem } from "@mui/material";
import { deepOrange } from "@mui/material/colors";
import ListItemLink from "app/@components/ListItemLink";
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useAppSelector } from "@core/hooks";
import { IUser } from "auth/logic/domain/User";
import AddBoxIcon from '@mui/icons-material/AddBox';
import smallLogo from '../images/default-small-logo.svg';
import LoadSiteSettings from "@common/@components/LoadSiteSettings";
import { createOrderAccess, selectAccessInfo } from "@core/permissionsHelper";

export default function ActionBarDrawer() {
  const { user = {} as IUser } = useAppSelector((state) => state.auth);
  const generalSettings = useAppSelector((state) => state.app.generalSettings);
  const accessInfo = useAppSelector((state) => selectAccessInfo(state))

  const initials = user.initials ||
    (user.name.length > 2
      ? user.name.slice(0, 2)
      : user.name);


  return (
    <>
      <LoadSiteSettings />
      <List
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2
        }}
      >
        <ListItem disablePadding
          sx={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Avatar sx={{ bgcolor: deepOrange[500] }} variant="square">
            {initials.toUpperCase()}
          </Avatar>
        </ListItem>
        {createOrderAccess(accessInfo) &&
          <ListItemLink
            {...{
              path: '/create-order',
              icon: () => <AddBoxIcon color="primary" />
            }}
          />
        }
      </List>
      <Box display="flex" alignSelf="center" alignItems="center" sx={{ flex: 1 }}>
        <img width={32} height={32} src={generalSettings?.smallLogoUrl || smallLogo} alt="logo icon" />
      </Box>
      <List>
        {/* <ListItemLink
          {...{
            path: '/mail',
            icon: () => <MailOutlineIcon />
          }}
        />
        <ListItemLink
          {...{
            path: '/ring',
            icon: () => <NotificationsNoneOutlinedIcon />
          }}
        /> */}
        <ListItemLink
          {...{
            path: '',
            icon: () => <LogoutOutlinedIcon />
          }}
        />
      </List>
    </>
  );
}

