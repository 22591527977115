const localStorageHelper = (() => {
    const authTokenKey = 'auth-token';
    const refreshTokenKey = 'refresh-token';
    const themeMode = 'theme-mode';

    const setValueToStore = (key: string, value: string) => {
        if (value) {
            localStorage.setItem(key, value);
        }
    };

    return {
        getAuthToken: () => {
            return localStorage.getItem(authTokenKey);
        },
        removeAuthToken: () => {
            localStorage.removeItem(authTokenKey);
        },
        setAuthToken: (val: string) => {
            setValueToStore(authTokenKey, val);
        },
        getRefreshToken: () => {
            return localStorage.getItem(refreshTokenKey);
        },
        removeRefreshToken: () => {
            localStorage.removeItem(refreshTokenKey);
        },
        setRefreshToken: (val: string) => {
            setValueToStore(refreshTokenKey, val);
        },
        getThemeMode: () => {
            return localStorage.getItem(themeMode);
        },
        setThemeMode: (val: string) => {
            setValueToStore(themeMode, val);
        },
        removeThemeMode: () => {
            localStorage.removeItem(themeMode);
        }
        // add others methods
    }
})();

export default localStorageHelper;