import { Box, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useLoadingBoxStyles = makeStyles(() => ({
  root: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
  },
}));

const LoadingBox = () => {
  const styles = useLoadingBoxStyles();

  return (
    <Box display="flex" justifyContent="center" alignItems="center" p={2} className={styles.root} >
      <CircularProgress />
    </Box>
  );
}

export default LoadingBox;
