export const StatusColorMap = {
    DELIVERED: { color: '#52d937', visible: true, order: 1 },
    IN_TRANSIT: { color: '#2073a1', visible: true, order: 2 },
    BOOKED: { color: '#52d937', visible: true, order: 3 },
    PLANNED: { color: '#edb420', visible: true, order: 4 },
    CANCELED: { color: '#D92D52', visible: true, order: 5 },


    RATED: { color: '#ed7c20', visible: false, order: 6 },
    QUOTE: { color: '#D92D52', visible: false, order: 7 },
    QUOTED: { color: '#D92D52', visible: false, order: 8 },
    PENDING: { color: '#2073a1', visible: false, order: 9 },
    ARRIVED: { color: '#2073a1', visible: false, order: 10 },
    DISPATCHED: { color: '#D92D52', visible: false, order: 11 },
    DISPATCH_EXPIRED: { color: '#f61515', visible: false, order: 12 },
    COMPLETED: { color: '#52d937', visible: false, order: 13 },
    CHECKED_IN: { color: '#fbe226', visible: false, order: 14 },
    UNSCHEDULED: { color: '#868686', visible: false, order: 15 },
    DISPATCH_REJECTED: { color: '#ce2828', visible: false, order: 16 },
}
export type StatusColorMapType = keyof typeof StatusColorMap;