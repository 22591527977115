import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc)

export function today() {
    const d = dayjs()
    let now = d.utc().startOf('D');
    now = now.set('D', d.date())
    return now;
}

export const defaultFormat = "MM/DD/YYYY HH:mm";
export const defaultFormatWithoutTime = "MM/DD/YYYY";
export const defaultMonth = "MM/YYYY";
export const defaultFullMonth = "MMM YYYY";
export const defaultDateFormat = "MM/dd/yyyy";

export function dateFormat(date: Date | string, showTime: boolean = true) {
    return dayjs(date).format(showTime ? defaultFormat : defaultFormatWithoutTime);
}

export function dateMonthFormat(date: Date | string, showFullDate: boolean = false) {
    return dayjs(date).format(showFullDate ? defaultFullMonth : defaultMonth);
}

export function getFormattedTime(date: Date | number | string) {
    const parsedDate = new Date(date);
    const hours = String(parsedDate.getHours()).padStart(2, '0');
    const minutes = String(parsedDate.getMinutes()).padStart(2, '0');
    const seconds = String(parsedDate.getSeconds()).padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
}