import { getServerAddress } from "@core/appVariables";
import { fetchClient } from "@core/fetchClient";
import { RootState } from "@core/store";
import { createAsyncThunk, createSelector } from "@reduxjs/toolkit";
import { IAmosOrderDto, IAmosOrderRequestDto, IAmosOrderResultDto } from "contracts/amos";


export const getOrdersAsync = createAsyncThunk(
    'dock-scheduler/orders',
    async (request: IAmosOrderRequestDto) => {
        const response = await fetchClient().post<IAmosOrderRequestDto, IAmosOrderResultDto>(`${getServerAddress('/orders/api')}/orders`, request)

        return response!;
    }
);

const roundToNearestHalfHour = (date: Date): Date => {
    const time = new Date(date);
    time.setMinutes(time.getMinutes() >= 30 ? 30 : 0, 0, 0);
    
    return time;
};

const groupOrdersByTime = (orders: IAmosOrderDto[]) => {
    return orders.reduce((acc, order) => {
        const roundedDate = roundToNearestHalfHour(new Date(order.lastEvent.eventEarlyDate));
        const dateKey = roundedDate.toISOString();
        
        if (!acc[dateKey]) {
            acc[dateKey] = [];
        }
        acc[dateKey].push(order);

        return acc;
    }, {} as Record<string, IAmosOrderDto[]>);
};

export const selectGroupedOrders = createSelector(
    (state: RootState) => state.dockSchedulerOrder.orders,
    (orders) => {
        return groupOrdersByTime(orders.results);
    }
);