import useAppTheme from "@core/appTheme";
import OrderDetailReferencesRow from "./OrderDetailReferencesRow";
import { useAppDispatch, useAppSelector } from "@core/hooks";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useEffect } from "react";
import { getReferenceTypes } from "orderDetail/@store/orderReferencesStore";
import OrderDetailCreateReference from "./OrderDetailCreateReference";

const OrderDetailReferences = () => {
    const dispatch = useAppDispatch();
    const theme = useAppTheme();

    const user = useAppSelector((state) => state.auth.user);
    const references = useAppSelector((state) => state.orderReferences.references);

    useEffect(() => {
        if (user?.branchId) {
            dispatch(getReferenceTypes(user.branchId))
        }
    }, [])

    return (
        <Table>
            <TableHead sx={{
                backgroundColor: theme.palette.action.hover
            }}>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Reference</TableCell>
                    <TableCell>Primary</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody sx={{ backgroundColor: theme.palette.background.paper }}>
                <OrderDetailCreateReference />
                {references?.map((item, index) => (
                    <OrderDetailReferencesRow guid={item.guid} key={index} />
                ))}
            </TableBody>
        </Table>
    )
}

export default OrderDetailReferences;