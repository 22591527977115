import * as React from 'react';
import { styled, Theme, CSSObject, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import ModeNightOutlinedIcon from '@mui/icons-material/ModeNightOutlined';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuOpenSharpIcon from '@mui/icons-material/MenuOpenSharp';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Switch } from '@mui/material';
import theme, { colorPalette } from './theme';
import LayoutTitle from './@components/LayoutTitle';
import { useAppSelector } from '@core/hooks';
import { selectAuth } from 'auth/logic/selectors';
import ActionBarDrawer from './@components/ActionBarDrawer';
import MenuDrawer from './@components/MenuDrawer';
import { useEffect } from 'react';
import localStorageHelper from '@core/localstorageHelper';

const drawerWidth = 215;
const miniDrawerSpacing = 8;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer - 200 + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  width: `calc(100%  - ${theme.spacing(miniDrawerSpacing * 2)})`,
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px - ${theme.spacing(miniDrawerSpacing)})`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function LayoutContent({ children }: React.PropsWithChildren) {
  const [open, setOpen] = React.useState(false);
  const [nightMode, setNightMode] = React.useState(false);
  const { isLoggedIn } = useAppSelector(selectAuth);
  
  const selectedThem = theme(nightMode);
  const palette = colorPalette(nightMode);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const storedThemeModeValue = localStorageHelper.getThemeMode();

    setNightMode(storedThemeModeValue ? storedThemeModeValue === 'true' : false)
  }, [])

  const changeThemeMode = (mode: boolean) => {
    setNightMode(mode);

    localStorageHelper.setThemeMode(`${mode}`);
  };

  {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */ }
  if (!isLoggedIn) {
    return (
      <ThemeProvider theme={selectedThem}>
        <CssBaseline />
        <main> {children} </main>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={selectedThem}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
              }}
            >
              <MenuOpenSharpIcon style={{ ...(!open && { transform: 'rotate(180deg)' }) }} />
            </IconButton>
            <LayoutTitle />
            <ModeNightOutlinedIcon />
            <FormControlLabel
              value="start"
              control={<Switch color="secondary" checked={nightMode} onChange={(_, v) => changeThemeMode(v)} />}
              label={<>Night mode</>}
              labelPlacement="start"
            />
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" PaperProps={{ style: { backgroundColor: palette.miniDrawerColor } }}>
          <ActionBarDrawer />
        </Drawer>
        <Drawer variant="permanent" PaperProps={{ style: { left: 64 } }} open={open}>
          <MenuDrawer open={open} />
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3, overflowX: "hidden" }}>
          <DrawerHeader />
          <CssBaseline />
          <main> {children} </main>
        </Box>
      </Box>
    </ThemeProvider >
  );
}