import { useAppDispatch, useAppSelector } from '@core/hooks';
import { AppNotify, dismissAppNotification } from './store/appNotificationStore';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { Button } from '@mui/material';


const CLOSE_NOTIFICATION_TIMEOUT = 10000;

function MessageContent({ messages, message }: AppNotify) {
  if (messages && messages.length > 0) {

    return (
      <div>
        {messages.map((t: string) => (<>{t}<br /></>))}
      </div>
    );
  } else {
    return message;
  }

}


function MessageContainer() {
  const dispatch = useAppDispatch();
  const { notifications } = useAppSelector((state) => state.notification);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    notifications.forEach(item => {
      enqueueSnackbar(MessageContent(item), {
        variant: item.kind,
        action: (key) => (
          <>
            <Button size='small' onClick={() => closeSnackbar(key)}>
              Close
            </Button>
          </>
        )
      });
      dispatch(dismissAppNotification(item.id))
    })
  },
    [notifications])

  return null;
}

export default function AppNotificationBar() {
  return (
    <SnackbarProvider maxSnack={3} autoHideDuration={CLOSE_NOTIFICATION_TIMEOUT} anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    >
      <MessageContainer />
    </SnackbarProvider>
  )
}