import { useAppSelector } from "@core/hooks";
import { selectAccessInfo } from "@core/permissionsHelper";
import { List } from "@mui/material";
import ListItemLink from "app/@components/ListItemLink";
import getMenuItems from "app/MenuItems";

type Props = {
  open: boolean,
}

export default function MenuDrawer({ open }: Props) {
  const accessInfo = useAppSelector((state) => selectAccessInfo(state))

  return (
    <List key='MenuDrawer'>
      {getMenuItems(accessInfo).map((item, index) => (
        <ListItemLink
          key={`${index}+${item.path}`}
          {...{
            path: item.path,
            open,
            title: item.title,
            icon: item.iconComponent
          }}
        />
      ))}
    </List>
  );
}