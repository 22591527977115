import { StateKind } from "@common/state";
import { getServerAddress } from "@core/appVariables";
import { fetchClient } from "@core/fetchClient";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IAmosCreateOrderDto, IAmosCreateOrderResponseDto, IAmosLookupDto } from "contracts/amos";

export type OrderEventSaveState = 'NotStarted' | 'InProgress'

export interface IOrderCreatedState {
    successful?: boolean;
    orderGuid?: string;
    errorMessage?: string;
}

interface ICreateOrderState {
    status: StateKind,
    isMapLoaded: boolean;
    customerBranch: string;
    orderCreated: IOrderCreatedState;
    lookup?: IAmosLookupDto;
    currentEvent?: number;
    needSaving: boolean;
    saveState: OrderEventSaveState;
}

const initialState: ICreateOrderState = {
    status: 'notLoaded',
    isMapLoaded: false,
    customerBranch: "",
    orderCreated: {},
    needSaving: false,
    saveState: "NotStarted",
}

export const createOrder = createAsyncThunk(
    'order/create',
    async (dto: IAmosCreateOrderDto, { rejectWithValue }) => {
        try {
            const response = await fetchClient().post<IAmosCreateOrderDto, IAmosCreateOrderResponseDto>(`${getServerAddress('/orders/api')}/orders/create`, dto);

            return response;
        }
        catch (error: any) {
            const msg = JSON.parse(error.message as string) as any;
            const errors = msg.errors ?? [];

            if (msg.invalidFields) {
                errors.push(...msg.invalidFields.map((x: any) => `${x.fieldName} - ${x.message}`));
            }

            return rejectWithValue({ messages: errors });
        }
    }
)

export const getLookup = createAsyncThunk(
    'lookup',
    async (branchGuid: string) => {
        const response = await fetchClient().get<IAmosLookupDto>(`${getServerAddress('/orders/api')}/lookup/${branchGuid}`);

        return response;
    }
)

export const createOrderStore = createSlice({
    name: 'CreateOrderStore',
    initialState,
    reducers: {
        setMapLoadedState: (state, action: PayloadAction<boolean>) => {
            state.isMapLoaded = action.payload;
        },
        setCustomerBranch: (state, action: PayloadAction<string>) => {
            state.customerBranch = action.payload;
        },
        setCurrentEvent: (state, action: PayloadAction<number>) => {
            state.saveState = action.payload ? "InProgress" : "NotStarted";
            state.currentEvent = action.payload;
        },
        setNeedSave: (state, action: PayloadAction<boolean>) => {
            state.needSaving = action.payload;
        },
        clearStore: () => {
            return {
                ...initialState,
                orderCreated: { ...initialState.orderCreated },
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createOrder.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createOrder.fulfilled, (state, action: PayloadAction<IAmosCreateOrderResponseDto | undefined>) => {
                state.status = 'loaded';
                state.orderCreated.successful = true;
                state.orderCreated.orderGuid = action.payload?.orderGuid;
            })
            .addCase(createOrder.rejected, (state, error) => {
                state.status = 'failed';
                state.orderCreated.successful = false;
                state.orderCreated.errorMessage = error.error.message;
            })
            .addCase(getLookup.pending, (state: ICreateOrderState) => {
                state.status = 'loading';
            })
            .addCase(getLookup.fulfilled, (state: ICreateOrderState, action: PayloadAction<IAmosLookupDto | undefined>) => {
                state.status = 'loaded';
                state.lookup = action.payload;
            })
            .addCase(getLookup.rejected, (state: ICreateOrderState, error) => {
                state.status = 'failed';
            })
    }
})

export const CreateOrderStoreAction = createOrderStore.actions;
export default createOrderStore.reducer;