import { Modal } from "@mui/material";
import { ReactNode } from "react";

export interface INalModalProps {
    component: ReactNode;
    open: boolean;
    onClose: () => void;
};

const NalModal = (props: INalModalProps) => {

    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
        >
            <>
                {props.component}
            </>
        </Modal>
    )
}

export default NalModal;