import { Card, CardHeader, Typography, CardContent, Grid, Box } from "@mui/material";
import useAppTheme from "@core/appTheme";
import Brightness7Icon from '@mui/icons-material/Brightness7';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import { EventType, IAmosEventDto } from "contracts/amos";
import { dateFormat } from "@core/dateHelper";
import DangerousIcon from '@mui/icons-material/Dangerous';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    cart: {
        maxWidth: 400,
        minWidth: 300,
        height: "100%"
    }
}));

interface IEventDetailCardProps {
    event: IAmosEventDto,
    itemsIds: (string | null)[]
}

interface ICardContentItem {
    title: string,
    titleColor?: string,
    value?: string,
    valueColor?: string,
    whiteSpaceNormal?: boolean,
}

const CardContentItem = (props: ICardContentItem) => {
    const theme = useAppTheme();

    return (
        <Grid item display="grid" gridTemplateColumns="1fr 1fr">
            <Typography variant="subtitle2" color={props.titleColor ?? theme.palette.text.secondary}>{props.title}</Typography>
            <Typography variant="subtitle2" color={props.valueColor ?? ""} whiteSpace={props.whiteSpaceNormal ? 'normal' : 'nowrap'}>{props.value}</Typography>
        </Grid>
    );
}

const EventDetailCard = (props: IEventDetailCardProps) => {
    const classes = useStyles();
    const theme = useAppTheme();

    const getEventTypeName = (): string => {
        return props.event.type === EventType.PICKUP.toLocaleUpperCase() ? EventType.PICKUP.toString() : EventType.DROP.toString();
    }

    return (
        <Card
            variant="outlined"
            className={classes.cart}>
            <CardHeader
                sx={{
                    borderBottom: "1px solid",
                    borderColor: theme.palette.grey[400]
                }}
                action={
                    <>
                        <Box display="flex" alignItems="center" gap={1}>
                            <Typography>
                                {`
                                    ${props.event.itemsTotals.lowTemperature
                                        ? (props.event.itemsTotals.lowTemperature + props.event.itemsTotals.temperatureUOM[0] + (props.event.itemsTotals.highTemperature && "-"))
                                        : ""}
                                    ${props.event.itemsTotals.highTemperature ? (props.event.itemsTotals.highTemperature + props.event.itemsTotals.temperatureUOM[0]) : ""}
                                `}
                            </Typography>
                            {props.event.itemsTotals.lowTemperature || props.event.itemsTotals.highTemperature && <Brightness7Icon color="secondary" />}
                        </Box>
                        <Box display="flex" justifyContent="flex-end">
                            {props.event.itemsTotals.hazardous && <DangerousIcon color="error" />}
                        </Box>
                    </>
                }
                title={
                    <Box display="flex" alignItems="center" gap={1}>
                        <ViewInArIcon color="action" />
                        <Box>
                            {props.itemsIds.map((itemId, index) => {
                                return (
                                    <Typography key={index} variant="body2">{itemId}</Typography>
                                );
                            })}
                            <Typography variant="body2">
                                {`${props.event.itemsTotals.totalWeight ? props.event.itemsTotals.totalWeight + ' ' + props.event.itemsTotals.weightUOM + ',' : ''} 
                                ${props.event.itemsTotals.totalQuantity ? props.event.itemsTotals.totalQuantity : ''} ${props.event.itemsTotals.packageType}`}
                            </Typography>
                        </Box>
                    </Box>
                }
                subheader={
                    <Typography variant="body2">{props.event.appointmentNumber}</Typography>
                }
            />
            <CardContent>
                <Grid container spacing={1} fontSize={12} display="flex" flexDirection="column">
                    <CardContentItem title="Appointment Number:" value={props.event.appointmentNumber} />
                    <CardContentItem title={`${getEventTypeName()} Range Early:`} value={dateFormat(props.event.eventEarlyDate)} />
                    <CardContentItem title={`${getEventTypeName()} Range Late:`} value={dateFormat(props.event.eventLateDate)} />
                    {props.event.estimatedTimeOfArrival && <CardContentItem title="ETA: " value={dateFormat(props.event.estimatedTimeOfArrival)} />}
                    <CardContentItem title="Distance to next stop:" value={props.event.distanceToNextStop ? `${props.event.distanceToNextStop.distance} ${props.event.distanceToNextStop.distanceUom}` : "End of trip"} />
                    <Grid item display="grid" gridTemplateColumns="1fr 1fr">
                        <Typography variant="subtitle2" color={theme.palette.text.secondary}>Location:</Typography>
                        <Box>
                            <Typography variant="subtitle2" whiteSpace={'normal'}>{props.event.location.address1}</Typography>
                            <Typography variant="subtitle2" whiteSpace={'normal'}>{props.event.location.address2}</Typography>
                            <Typography variant="subtitle2" whiteSpace={'normal'}>{`${props.event.location.city}${props.event.location.state ? ', ' + props.event.location.state : ''}${props.event.location.zip ? ', ' + props.event.location.zip : ''}${props.event.location.country ? ', ' + props.event.location.country : ''}`}</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default EventDetailCard;