import { StateKind } from "@common/state";
import { getServerAddress } from "@core/appVariables";
import { fetchClient } from "@core/fetchClient";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDashboardKpiStatusFilterDto } from "contracts/dashboard";
import { IPerformanceSuppliersDto } from "contracts/performance";


interface IPerformanceSupplierState {
    status: StateKind;
    suppliers?: IPerformanceSuppliersDto[];
}

const initialState: IPerformanceSupplierState = {
    status: 'notLoaded'
}

export const getSupplier = createAsyncThunk(
    'performance-supplier/get',
    async (request: IDashboardKpiStatusFilterDto) => {
        const response = await fetchClient().post<IDashboardKpiStatusFilterDto, IPerformanceSuppliersDto[]>(`${getServerAddress('/analytics/api')}/performance-suppliers`, request);

        return response;
    }
)

export const performanceSupplierStore = createSlice({
    name: 'performance-supplier',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSupplier.pending, (state: IPerformanceSupplierState) => {
                state.status = 'loading';
            })
            .addCase(getSupplier.fulfilled, (state: IPerformanceSupplierState, action: PayloadAction<IPerformanceSuppliersDto[] | undefined>) => {
                state.suppliers = action.payload;
                state.status = 'loaded';
            })
            .addCase(getSupplier.rejected, (state: IPerformanceSupplierState, error) => {
                state.status = 'failed';
            })
    }
});

export const PerformanceSupplierAction = performanceSupplierStore.actions;

export default performanceSupplierStore.reducer;
