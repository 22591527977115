import { StateKind } from '@common/state';
import { getServerAddress } from '@core/appVariables';
import { fetchClient } from '@core/fetchClient';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGeneralSettingsDto } from 'contracts/general';
import { ReactNode } from 'react';

export interface AppState {
  title?: string | ReactNode;
  generalSettings?: IGeneralSettingsDto;
  status: StateKind;
}

const initialState: AppState = {
  status: "notLoaded"
};

export const getSiteSettings = createAsyncThunk(
  'siteSettings',
  async () => {
    const response = await fetchClient().get<IGeneralSettingsDto>(`${getServerAddress('/orders/api')}/siteSettings/logo`);

    return response;
  }
)

export const appStore = createSlice({
  name: 'app',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setTitle: (state, { payload }: PayloadAction<{ title?: string | ReactNode }>) => {
      state.title = payload.title;
    },
  },
  extraReducers: (builder) => {
    builder
        .addCase(getSiteSettings.pending, (state: AppState) => {
            state.status = 'loading';
        })
        .addCase(getSiteSettings.fulfilled, (state: AppState, action: PayloadAction<IGeneralSettingsDto | undefined>) => {
            state.status = 'loaded';
            state.generalSettings = action.payload;
        })
        .addCase(getSiteSettings.rejected, (state: AppState, error) => {
            state.status = 'failed';
        })
  },
});

export const { setTitle } = appStore.actions;

export default appStore.reducer;
