export class AuthHelper {
    public static readonly loginPath = '/login';
    public static readonly refreshTokenPath = '/token/refresh';
    public static readonly unauthorizedStatusCode = '401';


    // TODO: generate all claims from BE.
    public static readonly claims = {
        ispersistent: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/ispersistent',
        role: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role',
        email: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress',
        name: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name',
        nameidentifier: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier',
        subject: 'sub',
        branchId: 'nal.claims.branch.id',
        branchName: 'nal.claims.branch.name',
        initials: 'nal.claims.user.initials',
        availableDocumentTypeGuids: 'nal.claims.user.document.type',
        permissions: 'nal.claims.permission',
        reportTypes: 'nal.claims.user.report.types'
    }
}