import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { EventType } from "contracts/amos";

export interface IOrderEvent {
    eventId: number;
    positionIndex: number;
    eventType: EventType;
    itemsSystemId: string[];
    location: IOrderEventAddress;
    info: IOrderEventInfo;
    appointment: IOrderEventAppointmentInfo;
}


export interface IOrderEventAddress {
    eventId?: number;
    locationName: string;
    address1: string;
    address2: string | undefined;
    city: string;
    state: string;
    zip: string;
    country: string;
    latitude: number;
    longitude: number;
    contactName: string | undefined;
    phone: string | undefined;
    email: string | undefined;
    phoneExtension: string | undefined;
    comments: string | undefined;
}

export interface IOrderEventInfo {
    eventId?: number;
    stopNumber: string;
    firstComeFirstServe?: boolean;
    eventEarlyDate?: number;
    eventLateDate?: number;
    appointmentRequired: boolean;
}

export interface IOrderEventAppointmentInfo {
    eventId?: number;
    appointmentNumber?: string;
    appointmentDate?: number;
    appointmentEarlyTime?: number;
    appointmentLateTime?: number;
}

interface IOrderEventValidationState {
    eventId?: number;
    address: boolean;
    info: boolean;
    visited: boolean;
}

interface IOrderEventBase {
    positionIndex: number;
    eventSystemId: number;
    eventType: EventType;
    itemsSystemId: string[];
}

interface ICreateOrderEventState {
    baseInfo: IOrderEventBase[];
    address: IOrderEventAddress[];
    info: IOrderEventInfo[];
    appointment: IOrderEventAppointmentInfo[];
    validations: IOrderEventValidationState[];
}

const initialState: ICreateOrderEventState = {
    baseInfo: [
        {
            positionIndex: 0,
            eventSystemId: 0,
            eventType: EventType.PICKUP,
            itemsSystemId: ["0"]
        },
        {
            positionIndex: 1,
            eventSystemId: 1,
            eventType: EventType.DROP,
            itemsSystemId: []
        }
    ],
    address: [],
    info: [],
    appointment: [],
    validations: [
        {
            eventId: 0,
            address: true,
            info: true,
            visited: false,
        },
        {
            eventId: 1,
            address: true,
            info: true,
            visited: false,
        }
    ]
}

export const createOrderEventStore = createSlice({
    name: 'CreateOrderEventStore',
    initialState,
    reducers: {
        updateAddress: (state, action: PayloadAction<{ address: IOrderEventAddress, eventId: number }>) => {
            const addressIndex = state.address.findIndex(addr => addr.eventId === action.payload.eventId);

            if (addressIndex !== -1) {
                state.address[addressIndex] = { ...action.payload.address, eventId: action.payload.eventId };
            } else {
                state.address.push({ ...action.payload.address, eventId: action.payload.eventId });
            }
        },
        updateInfo: (state, action: PayloadAction<{ info: IOrderEventInfo, eventId: number }>) => {
            const infoIndex = state.info.findIndex(eventInfo => eventInfo.eventId === action.payload.eventId);

            if (infoIndex !== -1) {
                state.info[infoIndex] = { ...action.payload.info, eventId: action.payload.eventId };
            } else {
                state.info.push({ ...action.payload.info, eventId: action.payload.eventId });
            }
        },
        updateAppointment: (state, action: PayloadAction<{ appointment: IOrderEventAppointmentInfo, eventId: number }>) => {
            const appointmentIndex = state.appointment.findIndex(eventAppointment => eventAppointment.eventId === action.payload.eventId);

            if (appointmentIndex !== -1) {
                state.appointment[appointmentIndex] = { ...action.payload.appointment, eventId: action.payload.eventId };
            } else {
                state.appointment.push({ ...action.payload.appointment, eventId: action.payload.eventId });
            }
        },
        updateValidation: (state, action: PayloadAction<{ key: keyof (IOrderEventValidationState), value: boolean, eventId: number }>) => {
            const { key, value, eventId } = action.payload;
            const eventIndex = state.validations.findIndex(validation => validation.eventId === eventId);
            const eventValidation = state.validations[eventIndex];

            if (eventIndex !== -1) {
                state.validations[eventIndex] = {
                    ...eventValidation,
                    [key]: value,
                };
            }
        },
        setAppointmentRequired: (state, action: PayloadAction<{ eventId: number }>) => {
            const appointmentIndex = state.appointment.findIndex(eventAppointment => eventAppointment.eventId === action.payload.eventId);

            if (appointmentIndex !== -1) {
                state.appointment[appointmentIndex] = {};
            }
        },
        updateEventItems: (state, action: PayloadAction<{ ids: string[], eventId: number }>) => {
            const eventIndex = state.baseInfo.findIndex(event => event.eventSystemId === action.payload.eventId);

            if (eventIndex !== -1) {
                state.baseInfo[eventIndex].itemsSystemId = action.payload.ids;
            }
        },
        setAllVisited: (state) => {
            const updatedValidations = state.validations.map(validation => ({
                ...validation,
                visited: true,
            }));

            state.validations = updatedValidations;
        },
        addEvent: (state, action: PayloadAction<{ eventType: EventType, eventIndex: number }>) => {
            const newEventIndex = action.payload.eventIndex + 1;
            const eventSystemId = Math.max(...state.baseInfo.map(event => event.eventSystemId)) + 1;
            const newEvent: IOrderEventBase = {
                positionIndex: newEventIndex,
                eventSystemId: eventSystemId,
                eventType: action.payload.eventType,
                itemsSystemId: []
            };

            state.baseInfo.splice(newEventIndex, 0, newEvent);
            state.validations.splice(newEventIndex, 0, { eventId: eventSystemId, address: true, info: true, visited: false });

            for (let i = newEventIndex + 1; i < state.baseInfo.length; i++) {
                state.baseInfo[i].positionIndex++;
            }
        },
        deleteEvent: (state, action: PayloadAction<number>) => {
            const eventIdToDelete = action.payload;

            state.baseInfo = state.baseInfo.filter(event => event.eventSystemId !== eventIdToDelete);
            state.address = state.address.filter(address => address.eventId !== eventIdToDelete);
            state.info = state.info.filter(info => info.eventId !== eventIdToDelete);
            state.appointment = state.appointment.filter(appointment => appointment.eventId !== eventIdToDelete);
            state.validations = state.validations.filter(validation => validation.eventId !== eventIdToDelete);
        },
        updateEventPosition: (state, action: PayloadAction<{ eventId: number, newPositionIndex: number }>) => {
            const { eventId, newPositionIndex } = action.payload;
            const eventToMoveIndex = state.baseInfo.findIndex(event => event.eventSystemId === eventId);

            if (eventToMoveIndex !== -1) {
                const eventToMove = state.baseInfo[eventToMoveIndex];
                const newBaseInfo = [...state.baseInfo];

                newBaseInfo.splice(eventToMoveIndex, 1);
                newBaseInfo.splice(newPositionIndex, 0, eventToMove);

                const updatedBaseInfo = newBaseInfo.map((event, index) => ({
                    ...event,
                    positionIndex: index,
                }));

                return {
                    ...state,
                    baseInfo: updatedBaseInfo,
                };
            }

            return state;
        },
        setTransitDays: (state, action: PayloadAction<number>) => {
            const dropEventDate = state.info[1].eventEarlyDate;
            const pickupEventDate = state.info[0].eventEarlyDate;
            const daysToAdd = action.payload;

            if (state.baseInfo.length === 2
                && dropEventDate
                && pickupEventDate
            ) {
                if (daysToAdd < (new Date(dropEventDate).getDate() - new Date(pickupEventDate).getDate())) {
                    const date = new Date(pickupEventDate);
                    date.setDate(date.getDate() + daysToAdd);
                    state.info[1].eventEarlyDate = date.valueOf();
                }

                const pickupEventLateDate = state.info[0].eventLateDate;
                if (pickupEventLateDate) {
                    const date = new Date(pickupEventLateDate);
                    date.setDate(date.getDate() + daysToAdd);
                    state.info[1].eventLateDate = date.valueOf();
                }
                else {
                    state.info[1].eventLateDate = undefined;
                }

                const appointment = state.appointment[0];
                if (state.info[0].appointmentRequired && appointment && appointment.appointmentDate) {
                    const date = new Date(appointment.appointmentDate);
                    date.setDate(date.getDate() + daysToAdd);
                    state.info[1].appointmentRequired = true;
                    state.appointment[1].appointmentDate = date.valueOf();
                }
            }
        },
        clearStore: () => {
            return {
                address: initialState.address.map(a => ({ ...a })),
                info: initialState.info.map(i => ({ ...i })),
                appointment: initialState.appointment.map(app => ({ ...app })),
                validations: initialState.validations.map(v => ({ ...v })),
                baseInfo: initialState.baseInfo.map(e => ({ ...e }))
            };
        },
    }
})

export const CreateOrderEventStoreAction = createOrderEventStore.actions;
export default createOrderEventStore.reducer;