import { downloadFile } from "@common/nalFileManager";
import { getServerAddress } from "@core/appVariables";
import { fetchClient } from "@core/fetchClient";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IReportOrderAssessorialDetailsDto, IReportStatusFilterDto, IReportTextFilterDto } from "contracts/report";

export const getOrderAssessorialReportDetail = createAsyncThunk(
    'reports/order-assessorial/details',
    async (request: IReportStatusFilterDto) => {
        const response = await fetchClient().post<IReportStatusFilterDto, IReportOrderAssessorialDetailsDto>(`${getServerAddress('/analytics/api')}/orderDetailsAssessorial/details`, request);

        return response;
    }
)

export const downloadOrderAssessorialDetailAsExcel = createAsyncThunk(
    'reports/order-assessorial/{branchGuid}/xml',
    async (request: IReportStatusFilterDto) => {
        const fileData = await fetchClient().stream(`${getServerAddress('/analytics/api')}/orderDetailsAssessorial/export`, "POST", request, 'application/json');

        downloadFile(fileData.name, fileData.contentType, new Uint8Array(await fileData.content.arrayBuffer()));
    }
);

export const getAdditionalChargesNames = createAsyncThunk(
    'reports/order-assessorial/charges-names',
    async (request: IReportStatusFilterDto) => {
        const data = await fetchClient().post<IReportTextFilterDto, string[]>(`${getServerAddress('/analytics/api')}/orderDetailsAssessorial/charges`, request);

        return data;
    }
)