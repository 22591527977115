import { Box, Button, Drawer } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ReactNode, useEffect, useState } from "react";
import useAppTheme from "@core/appTheme";

export interface IAuxiliaryProps {
    component: ReactNode;
};

export const AuxiliaryModal = (props: IAuxiliaryProps) => {
    const navigate = useNavigate();
    const theme = useAppTheme();

    const { id } = useParams();

    //workaround to use drawer animation
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        setShowModal(id ? true : false);
    }, [id]);

    const closeDrawer = () => {
        navigate("..");
    };

    return (
        <Drawer
            variant="temporary"
            anchor="right"
            open={showModal}
            onClose={closeDrawer}
            ModalProps={{
                keepMounted: false,
            }}
            PaperProps={{
                sx: {
                    width: 'max-content',
                    minWidth: 10,
                    maxWidth: '90vw',
                    overflowX: "hidden",
                    p: 3,
                    backgroundColor: theme.palette.background.default
                }
            }}
        >
            <Box>
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.info.contrastText,
                        position: "absolute",
                        right: 30,
                        top: 30,
                        minWidth: 40
                    }}
                    onClick={closeDrawer}
                >X</Button>
            </Box>
            <Box>
                {props.component}
            </Box>
        </Drawer>
    );
};